.application-card {
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  .bf-card-image {
    border-radius: 3px 3px 0 0;
    height: 62px;
    min-height: 62px;
  }

  .bf-card-content {
    margin-bottom: auto;
  }

  > button {
    border-radius: 0 0 3px 3px;
  }


}


.upload-csv-modal-header {
  font-size: var(--bf-font-size-h5);
  font-weight: var(--font-weight-3XL, 740);
}

.csv-uploader-select-box {
  display: flex;
  align-items: center;
  border: 1px var(--bfc-base-dimmed) solid;
  background: var(--bfc-base-2);
  padding: var(--bfs16); 
  border-radius: var(--bf-radius-s); 
}

.upload-csv-modal-text
{
  font-family: "Open Sans"; 
  color: var(--bfc-base-c-1); 
  font-weight: 400; 
  font-size: var(--bf-font-size-m); 
}
.application-card-v2 {
  box-shadow: 0px 4px 4px -3px rgba(0, 0, 0, 0.2);
  border-radius: 11px;
  background-color: var(--bfc-base-3);
  display: flex;
  flex-direction: row;

  .app-card-image {
    display: flex;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
    min-width: 155px;

    > img {
      //flex: 1;
      object-fit: contain;
      max-height: 100px;

      margin: auto;
    }

  }

  .app-card-content {
    padding: var(--bfs12);
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: row;
      margin-bottom: var(--bfs4);

      > h5 {
        margin: 0;
      }

      > span {
        margin-left: var(--bfs8);
        width: var(--bfs24);
        border-radius: 3px;
        display: flex;

        > p {
          margin: auto;
        }

      }
    }

    > p {
      margin-top: 0;
      margin-bottom: var(--bfs8);
    }

    .app-card-button {
      margin-bottom: 0;
      margin-top: auto;
    }

  }

}

@media (max-width: 1279px) {
  .application-card-v2 {
    flex-direction: column;

    .app-card-image {
      min-height: 100px;
      flex: 1;
      border-top-left-radius: 11px;
      border-top-right-radius: 11px;

      > img {
        object-fit: scale-down;
        max-height: 80px;
      }

    }
  }
}
