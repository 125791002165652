@import 'src/assets/sass/mixins/space';

.box-title {
  margin-top: 0;
  margin-bottom: var(--bfs12);
}

.box-container {
  @include space-vertically(var(--bfs12));

  .box-complete {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--bfc-base-1);
    opacity: 50%;
    z-index: 10;
  }

  .box-container-checker {
    flex-direction: column;
    align-items: center;


    .box-container-btngrp.unchecked {
      background-color: var(--bfc-warning);

      .btngrp-btns {
        > button {
          color: var(--bfc-alert-fade-c);
          background-color: var(--bfc-warning-fade);
        }
      }
    }

    .box-container-btngrp {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 3px;
      --bfl-columns: 1;
      --bfl-gap: var(--bfs8);

      padding: 0 var(--bfs12);
      background-color: var(--bfc-success);

      .btngrp-label {
        padding: 0;
        color: var(--bfc-warning-c);
      }

      .btngrp-btns {
        display: flex;
        align-items: center;
        flex-direction: row;
        --bfl-columns: 1;
        --bfl-gap: var(--bfs12);

        .btngrp-btn.unchecked {

          .bf-checkbox-icon {
            color: var(--bfc-alert-fade-c);
          }

          > * {
            color: var(--bfc-alert-fade-c);
            border-color: var(--bfc-warning-fade);
            background-color: var(--bfc-warning-fade);
          }

        }

        .btngrp-btn {

          .bf-checkbox-icon {
            color: var(--bfc-success-fade-c);
          }

          > * {
            color: var(--bfc-success-fade-c);
            border-color: var(--bfc-success-fade);
            background-color: var(--bfc-success-fade);
          }

        }

        > button {
          color: var(--bfc-success-fade-c);
          background-color: var(--bfc-success-fade);
        }

      }
      @media (min-width: 600px) {
        .btngrp-btns {
          --bfl-columns: 2;
        }
      }


    }
    @media (min-width: 600px) {
      .box-container-btngrp {
        --bfl-columns: 2;
      }
    }


    h2 {
      margin: var(--bfs16) 0 var(--bfs8) 0;
    }

    > div {
      display: flex;
      flex-direction: column-reverse;
    }
  }

}

.box {
  padding-bottom: var(--bfs12);
  display: flex;


  > *:first-child {
    flex: 1;
  }

  p {
    //margin: 0;
  }

  .input-wrapper {
    margin: 0;
  }

  .bottom-line {
    display: flex;
    justify-content: space-between;

    .table-size-dropdown {
      margin-top: var(--bfs8);
    }

    .row-box {
      display: flex;

      > div {
        width: 75px;
        margin-left: var(--bfs8);
        margin-top: var(--bfs8);
      }
    }
  }

}

.box-edit {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin: var(--bfs12) 0 0;

  p {
    margin: 0 var(--bfs16) 0 0;
  }
}
