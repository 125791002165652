#task-modal {
  display: flex;
  flex-direction: row;

  .task-modal-general {
    min-width: 500px;

    > div {
      margin-bottom: var(--bfs12);
    }

  }

  @media (max-width: 600px) {
    .task-modal-general {
      min-width: 0;
    }
  }

  > div {
    flex: 1;
    //margin: var(--bfs8);
  }
}
@media (max-width: 600px) {
  #task-modal {
    flex-direction: column;
  }
}
