.location-card {
  min-width: 300px;
  background-color: var(--bfc-base-3);
  display: flex;
  flex-direction: column;

  .image-container {
    max-width: 100%;
    position: relative;
    display: flex;
    //flex: 1;
    min-height: 150px;
    background-size: cover;
    background-position: 50% 50%;

  }

  .location-card-content {
    display: flex;
    flex-direction: column;
    padding: var(--bfs12);
    flex: 1;

    > button {
      margin-top: var(--bfs12);
      margin-bottom: var(--bfs8);
      //flex: 1;
    }

    > p {
      margin: 0 0 var(--bfs4);
    }

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;

      > svg {
        width: 14px;
      }

      > svg:first-of-type {
        margin-right: var(--bfs4);
        margin-top: 3px;
        align-self: flex-start;
      }

      > p {
        margin: 0;
      }

    }

    > div {
      margin-bottom: 1px;
    }


  }


}