.domain-card {
  display: flex;
  flex-direction: row;
  padding: var(--bfs16);
  background-color: var(--bfc-base-3);

  .domain-card-icon {
    background-color: var(--bfc-base-1);
    border-radius: 50%;
    display: flex;
    width: 80px;
    height: 80px;

    > * {
      margin: auto;
      height: 40px;
      width: 40px;
      color: var(--bfc-theme);
    }

  }

  .domain-card-body {
    flex: 1;
    margin: 0 var(--bfs12);

    > p {
      margin: var(--bfs2) 0;
    }

  }

  .domain-card-button {
    align-self: flex-end;

  }

}

@media (max-width: 600px) {
  .domain-card {
    flex-direction: column;
  }
}