
.overview-card {
  display: flex;
  flex-direction: column;
  height: 146px;
  border-radius: 11px;
  background-color: var(--bfc-base-3);
  color: var(--bfc-base-c-2);

  .card-header {
    padding: var(--bfs8);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--bfc-base-c-dimmed);

    > p {
      margin: 0;
    }

  }

  .finance-header {
    padding: var(--bfs8);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--bfc-base-c-dimmed);
    color: var(--bfc-base-c-1);

    > p {
      margin: 0 0 0 var(--bfs8);
    }

  }

  .card-body {
    display: flex;
    justify-content: center;
    flex: 1;

    > h1 {
      margin: auto;
      font-weight: 650;
    }

    > div {
      text-align: center;

      > h5 {
        color: var(--bfc-base-c-1);
      }
    }

    > p {
      margin: auto auto auto 0;
      color: var(--bfc-base-c-2);
    }

  }

}

.money-card {
  display: flex;
  flex-direction: column;
  height: 146px;
  min-width: 260px;
  border-radius: 11px;
  background-color: var(--bfc-base-3);

  .card-header {
    padding: var(--bfs8);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--bfc-base-c-dimmed);

    > p {
      margin: 0;
      margin-left: var(--bfs8);
    }

    > div {
      display: flex;
      flex-direction: row;
      margin: auto 0 auto auto;

      > p {
        margin: 0 var(--bfs8) 0 0;
      }

    }

  }

  .card-body {
    display: flex;
    justify-content: space-evenly;
    padding: 20px var(--bfs8);
    flex: 1;

    .separator {
      height: 100%;
      border-right: 1px solid var(--bfc-base-c-dimmed);
      padding: 0;
    }

    > div {
      padding: 0 var(--bfs8);

      > h1 {
        text-align: center;
        margin: auto;
      }

      > p {
        text-align: center;
        margin: auto auto auto 0;
        color: var(--bfc-base-c-2);
      }
    }


  }
}

.money-card-link {
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.money-card-link:hover {

  .card-header {

    span {

      > p {
        text-decoration: underline;
      }

    }

  }
}

.settings-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 146px;
  min-width: 260px;
  border-radius: 11px;
  background-color: var(--bfc-base-3);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .card-header {
    padding: var(--bfs8);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--bfc-base-c-dimmed);


    span {
      display: flex;
      flex: 1;
      justify-content: space-between;

      > p {
        margin: 0;
        margin-left: var(--bfs8);
      }

    }

  }

  .card-body {
    display: flex;
    justify-content: space-evenly;
    padding: 20px var(--bfs8);
    flex: 1;

    .separator {
      height: 100%;
      border-right: 1px solid var(--bfc-base-c-dimmed);
      padding: 0;
    }

    > div {
      padding: 0 var(--bfs8);

      > h1 {
        text-align: center;
        margin: auto;
      }

      > p {
        margin: auto auto auto 0;
        text-align: center;
        color: var(--bfc-base-c-2);
      }
    }


  }
}

.settings-card:hover {

  .card-header {

    span {

      > p {
        text-decoration: underline;
      }

    }

  }

}


.values-card {
  display: flex;
  flex-direction: row;
  min-height: 120px;
  min-width: 260px;
  margin-bottom: var(--bfs24);

  .card-values {
    margin-left: var(--bfs12);
    padding: var(--bfs12);
    display: flex;
    flex-direction: column;
    background-color: var(--bfc-base-3);

    > span {
      margin: var(--bfs2) 0;
    }

  }

}