.pages-box-content {
  padding-bottom: 40px;
}
.pages-box-content.bfl-grid {
  --bfl-columns: 3;
  --bfl-gap: var(--bfs24);

  @media screen and (max-width: 600px) {
    --bfl-columns: 1;
    margin: 16px;
  }

  .overview-page-box.bf-card {
    border-radius: var(--bfs12);
    box-shadow: 0px 4px 8px 0px var(--bfc-shadow);

    > a {
      padding: var(--bfs24);

      .bf-card-logo {
        height: 52px;
        width: 52px;
        border-radius: 0;
        margin: 0;
        background-color: var(--bfc-base-3);
      }

      .bf-card-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        color: var(--bfc-base-c-1);
        margin: 0px;
        margin-bottom: 4px;
        margin-top: 12px;
      }
      > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > svg {
          color: var(--bfc-base-c-2);
        }
      }

      > div {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
      }
      > div:last-of-type {
        margin-bottom: 0;
      }


    }

  }

  .overview-page-box:focus {
    outline: none;
  }

  .overview-page-box:focus-visible {
    outline: 1px dashed var(--bff-theme);
    -moz-outline-radius: var(--bfl-border-radius);
    /* make sure the outline is visible on top of expanded accordion item content */
    position: relative;
    z-index: 1;
  }


}

@media (max-width: 600px) {
  .pages-box-content {
    --bfl-columns: 1;
  }

}

@media (min-width: 960px) {
  .pages-box-content {
    padding-bottom: 40px;
  }
}