#billing {
  .content {
    display: flex;

    .content-main {
      width: 100%;

      .billing-box {
        display: flex;
        flex-direction: column;

        > h5 {
          margin-top: var(--bfs24);
          margin-bottom: var(--bfs8);
        }

        .droppable {
          --bfl-columns: 1;
        }
        @media (min-width: 1279px) {
          .droppable {
            --bfl-columns: 2;
          }
        }

        .billing-add-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

        }

        .box-list {
          --bfl-columns: 1;
          --bfl-gap: var(--bfs8);
        }
        @media (min-width: 1279px) {
          .box-list {
            --bfl-columns: 2;
          }
        }


      }

    }

  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        width: 80%;
      }
    }
  }


}