.application-user-slider {
  padding: 0;
  display: flex;
  flex-direction: column;

  > div {

    .slider-tabs {
      margin-top: var(--bfs8);
    }

    > button {
      margin: var(--bfs24);
      position: absolute;
      right: 0;
      top: 0;
    }

    > header {
      margin: var(--bfs24);
    }

    .tab-bar-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      background: var(--bfc-base-1);

      .tab-bar-body {
        padding: var(--bfl-box-padding);

        .selected-list {

          > table {
            width: 100%;
          }

          > div {
            display: flex;
            justify-content: space-between;

            > fieldset {

              .search-bar {
                margin-bottom: var(--bfs12);

                > div:first-of-type {
                  max-width: 250px;
                }

                > div:last-of-type {
                  min-width: 150px;
                }

              }
            }

          }



        }


      }


    }


  }


}