@import 'src/assets/sass/mixins/space';

.flex {
  display: flex;
  align-items: center;
  @include space-vertically(var(--bfs12));

  > * {
    flex: 1;
    @include space-beside(var(--bfs6));

    p {
      margin-bottom: var(--bfs4);
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}