.application-table-container {
  overflow: scroll;
  max-height: calc(100vh - (var(--bfl-page-padding) * 4) - 132px - var(--bf-nav-top-height) - 73px - 62px - 50px);

  .application-table {
    position: relative;
    overflow: scroll;
    scrollbar-color: var(--bfc-base-c-wcag) transparent;
    scrollbar-width: thin;

    > thead {
      position: sticky;
      background: var(--bfc-base-3);
      top: 0;
      z-index: 3;

      > tr {
        border-bottom: 1px solid var(--bfc-base-dimmed);


        > th {
          min-width: 200px;
        }

        > th:first-of-type {
          min-width: 19px;
          max-width: 19px;
          width: 19px;
          background: var(--bfc-base-3);
          position: sticky;
          z-index: 1;
          left: 0;
          border-right: 1px solid var(--bfc-base-dimmed);
        }

        @media (min-width: 600px) {
          .applications-overview-table-cell-with-border {
            background: var(--bfc-base-3);
            position: sticky;
            z-index: 1;
            left: 68px;
            border-right: 1px solid var(--bfc-base-dimmed);
          }
        }

        > th:last-of-type {
          max-width: 170px;
          min-width: 170px;
          background: var(--bfc-base-3);
          border-left: 1px solid var(--bfc-base-dimmed);
          position: sticky;
          z-index: 1;
          right: 0;
        }
      }

    }

    > tbody {

      > tr {

        > td:first-of-type {
          background: var(--bfc-base-3);
          border-right: 1px solid var(--bfc-base-dimmed);
          position: sticky;
          z-index: 1;
          left: 0;
        }

        @media (min-width: 600px) {
          .applications-overview-table-cell-with-border {
            background: var(--bfc-base-3);
            position: sticky;
            z-index: 1;
            left: 68px;
            border-right: 1px solid var(--bfc-base-dimmed);
          }
        }

        > td:last-of-type {
          background: var(--bfc-base-3);
          border-left: 1px solid var(--bfc-base-dimmed);
          position: sticky;
          z-index: 1;
          right: 0;
        }

      }

    }


  }

}

.application-table-container.expanded {
  max-height: calc(100vh - (var(--bfl-page-padding) * 2) - var(--bf-nav-top-height) - 80px - 50px);
}
