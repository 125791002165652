.domain-slider {
  flex: 1;

  .domain-slider-checkbox {
    border: 1px solid var(--bfc-base-dimmed);
    background-color: var(--bfc-base-3);
    padding: var(--bfs8) var(--bfs12);

  }

}