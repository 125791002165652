.bf-sidebar-appname {
  padding: 0 16px 0 21px;
  line-height: var(--bfs32);
  font-size: var(--bf-font-size-s);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: var(--bfl-border);
  color: var(--bfc-base-c-2);
  background-color: var(--bfc-base-2);
}

#onboard-navigation {

}

header {

  .profile-photo-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;

    > img {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      border: 1px solid var(--bfc-base-dimmed);
    }

  }
}

