#microsoft365 {
  margin-top: var(--bfl-page-padding);

  .content {
    display: flex;
    justify-content: space-around;
    padding: var(--bfl-page-padding);

    .content-main {
      width: 100%;
      padding: 0;
      max-width: 1170px;

      .microsoft365-box {
        margin-top: var(--bfs24);

        .microsoft365-box-container {
          display: flex;
          justify-content: center;

          .body-header {
            padding: 0 0 var(--bfs8) 0;
          }

        }
        @media (min-width: 800px) {
          .microsoft365-box-container {
            --bfl-columns: 3;
          }
        }

      }

    }

  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      padding: var(--bfl-page-padding);

      .content-main {
        width: 80%;
        padding: var(--bfl-page-padding) 0;
        max-width: 1170px;
      }
    }
  }

}