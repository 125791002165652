#backoffice-network-details {

  .progress-bar {
    margin-bottom: var(--bfs32);
  }

  .button-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .search-section {
      display: flex;
      flex-direction: row;

      > *:last-child {
        min-width: 75px;
      }

    }

  }

  .network-details-content {

    > * {
      margin-top: var(--bfs12);
    }

  }

}