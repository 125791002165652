.communication-note {
  > :first-child {
    padding: var(--bfs24);
  }

  .communication-note-header {
    display: flex;

    :first-child {
      flex: 1;
    }
  }

  .communication-note-row {
    background: var(--bfc-base-3);
    padding: var(--bfs12) var(--bfs24);

    .communication-note-row-text {
      flex: 1;
    }

    .communication-note-row-buttons {
      display: flex;
      justify-content: flex-end;

      button {
        margin-top: var(--bfs8);
      }

      button:last-child {
        margin-left: var(--bfs8);
      }

    }


    > div {
      display: flex;
      flex-direction: column;
    }


  }

  .communication-note-content {
    border-top: 1px solid var(--bfc-base-3);
    padding: var(--bfs24);
  }
}