.custom-app-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;

  .custom-app-selects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .submit-button {
    max-width: none;
  }
}
