
.error-500 {
  display: flex;
  flex-direction: column;
  margin: auto;

  > div {
    margin: auto;
  }

}