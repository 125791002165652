.workplace-import-btns {
  margin-top: var(--bfs16);
  padding: var(--bfs16) 0;
  display: flex;
  flex-direction: row;

  > * {
    margin-right: var(--bfs16);
  }

  > div {
    padding: var(--bfs4) var(--bfs8);
    border: 1px solid var(--bfc-base-c-dimmed);

    > p {
      margin-top: 0;
    }

  }

}

.workplace-replacement {
  margin-top: var(--bfs40);

  .workplace-btn-row {
    display: flex;
    align-items: flex-end;

    > * {
      min-width: 200px;
      margin-right: var(--bfs8);
    }

  }

  .workplace-table {
    margin-top: var(--bfs8);


  }

}