#finance {
  padding: var(--bfl-page-padding);

  .finance-header {

    .header-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > h2 {
        margin-top: var(--bfs8);
        margin-bottom: var(--bfs16);
      }

      .date-picker-row {

        * {
          z-index: 100;
        }

      }


    }


  }

  .finance-content {

    .finance-info-boxes {
      margin: 0 auto;
      max-width: 2480px;
    }

    .finance-graph-box {
      margin: 0 auto;
      margin-top: var(--bfs24);
      max-width: 2480px;
      min-width: 1502px;
      display: flex;

      .finance-graph {
        background-color: var(--bfc-base-3);
        border-radius: 11px;
        flex: 1;
        padding: var(--bfs16);
        width: 100%;
        overflow: scroll;
        position: relative;

        .finance-graph-topbar {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > span {
            display: flex;
            flex-direction: row;

            > span {
              display: flex;
              flex-direction: row;
              margin-right: var(--bfs12);

              > span {
                height: var(--bfs8);
                width: var(--bfs8);
                border-radius: 50%;
                margin: auto 0;
                margin-right: var(--bfs4);
              }

              > p {
                color: var(--bfc-base-c-2);
              }
            }
          }

        }


        .finance-graph-content {
          width: 95vw;
          max-width: 2448px;
          min-width: 1470px;
          position: relative;

          .vertical-content {
            max-width: 2448px;
            min-width: 1470px;

            > canvas {
              max-width: 2448px;
              min-width: 1470px;
            }

            .monthly-income-bar {
              --bfl-gap: var(--bfs32);
              width: 100%;
              // overflow: scroll;
              position: relative;

              > div {

                > div {
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 1px dashed var(--bfc-base-dimmed);

                  > p {
                    margin-bottom: 0;
                    z-index: 10;
                  }

                }

              }
            }
          }

          .horizontal-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: var(--bfs16);

            .finance-sum-content {

            }

            .year-count {
              margin-top: var(--bfs4);
              //margin-left: 25px;
              //margin-right: 25px;
              display: flex;

              > button {
                margin: 0 12px;
              }

              > span {
                display: flex;
                padding: 1px 1px 1px var(--bfs8);
                cursor: pointer;
                align-items: center;
                justify-content: flex-start;

                > p {
                  margin: 0;
                }

              }
              > span:hover {
                padding: 0 0 0 7px;
                border: 1px solid var(--bfc-base-dimmed);
              }

              > span:first-of-type {
                border-right: 1px solid var(--bfc-base-dimmed);
              }

            }

            .finance-year-count {
              margin-left: 75px;
              margin-right: 75px;
              display: flex;
              //padding-top: var(--bfs16);
              //border-top: 1px dashed var(--bfc-base-dimmed);



                  .week-block {
                    border-right: 1px solid var(--bfc-base-dimmed);
                    min-width: 24px;

                    > div {

                      > p {
                        margin: 0;
                        padding: var(--bfs2);
                        cursor: pointer;
                      }

                      > p:hover {
                        background-color: var(--bfc-base-dimmed);
                        border-radius: 3px;
                      }

                      > span {
                        min-width: 24px;
                        margin: 0 auto;

                        .money-bar {
                          z-index: 11;
                          background-color: var(--bfc-theme);
                          opacity: 0.4;
                          position: absolute;
                          bottom: 0;
                          width: 100%;
                          margin-bottom: var(--bfs16);
                          cursor: pointer;
                        }

                        .money-bar-active {
                          opacity: 1;
                        }

                        .money-bar {

                        }


                      }


                    }

                  }





              > div:last-of-type {
                border-right: 0;
              }

              > div {

                > span {
                  //position: absolute;
                  color: var(--bfc-theme);
                  display: flex;
                  text-align: center;
                  justify-content: center;

                  > p:first-child {
                    margin-right: var(--bfs4);
                  }

                }
              }

            }

          }

        }

      }

      .finance-graph-details {
        border-radius: 11px;
        width: 448px;
        margin-left: var(--bfs16);
        padding: var(--bfs24);
        background-color: var(--bfc-base-3);
        overflow: scroll;
        max-height: 903px;


        // position: absolute;
        // right: 0;
        // z-index: 11;
        // border-left: 1px solid var(--bfc-base-dimmed);

        .graph-details-content {


          > span {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > p {
              margin: 0;
              color: var(--bfc-base-c-2);
            }

            > button {
              cursor: pointer;
              //margin: var(--bfs2);
              //font-size: var(--bf-font-size-s);
              //white-space: nowrap;
              color: var(--bfc-base-c-1);
              //background: transparent;
              border: 0;
              align-items: center;
              //display: flex;
              padding: var(--bfs8);
              border-radius: 50%;

              > svg {
                height: 16px;
                width: 16px;
              }
            }

            > button:hover {
              background-color: var(--bfc-base-dimmed);
            }

          }

          .detail-week {

            > h3 {
              margin: 0;
              margin-bottom: var(--bfs16);
            }

            > div {
              margin-bottom: var(--bfs16);

              > p {
                margin: 0;
              }
            }


            > p {
              margin: 0 0 var(--bfs4);
            }

            .detail-company {
              background-color: var(--bfc-base-2);
              padding: var(--bfs12);
              border-radius: 11px;

              > p {
                margin: 0 0 var(--bfs8);
              }

              .detail-header {
                display: flex;
                justify-content: space-between;
                margin: 0;
                color: var(--bfc-base-c-1);

                > p {
                  color: var(--bfc-base-c-1);
                }

              }

              > div {
                display: flex;
                margin-bottom: var(--bfs8);
                align-content: center;

                > svg {
                  margin: auto 0;
                  margin-right: var(--bfs4);
                }

                > p {
                  margin: 0;
                }

              }

              .detail-badges {
                display: flex;

                > div {
                  margin-right: var(--bfs4);

                  > svg {
                    margin-right: var(--bfs4);
                  }

                }

              }

            }
          }
        }



      }

    }



  }


}