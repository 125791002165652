.communication-box-item {
  background: var(--bfc-base-2);

  .communication-header {
    background: var(--bfc-base-3);
    padding: var(--bfs8) var(--bfs16);

    > p {
      margin: 0;
    }

  }
  .communication-row:last-of-type {
    padding: var(--bfs16) var(--bfs24) var(--bfs16) var(--bfs24);
  }

  .communication-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--bfs16) var(--bfs24) 0px var(--bfs24);

    .communication-row-info {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 100%;
        margin-right: var(--bfs12);
        text-align: center;
      }
    }
  }
}
