
#compliance-main {
  display: flex;
  width: 100%;

  .box-container {
    //padding: var(--bfl-page-padding);
    width: 100%;

    .box {
      flex-direction: column;
    }

  }
  .articles-container {
    margin: 24px 0;
  }

  .accordion-header-box {
    display: flex;
    justify-content: space-between;

    .accordion-header {

      > h3 {
        margin-top: 0;
      }

    }

    .action-button-compliance {
      gap: 16px;

      > button:last-of-type {
        margin-left: var(--bfs16);
      }

    }
  }

  .accordion-container > .bf-accordion-container {
    margin-bottom: var(--bfs16);
  }

}


