#task-container {
  display: flex;

  .content-box {
    display: flex;
    flex: 1;
    flex-direction: column;

    .content-box-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      //margin-bottom: var(--bfs6);

      .content-box-header-topbar {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: var(--bfs8);

        > div {
          .header-select {
            min-width: 200px;
            margin-right: var(--bfs8);
          }

        }

        .header-buttonbar {
          display: flex;
          justify-content: center;

          > div {
            margin: auto;
          }

        }


      }

      @media (max-width: 520px) {
        .content-box-header-topbar {
          flex-direction: column;

          .header-select {
            margin-bottom: var(--bfs8);
          }

          .header-buttonbar {
            flex: 1;
            width: 100%;

            .bf-button-group {
              display: flex;
              flex: 1;

              > button {
                flex: 1;
              }

            }
          }

        }
      }

      .content-box-header-filters {
        margin-top: var(--bfs8);
        margin-bottom: var(--bfs8);

        > button {
          margin-right: var(--bfs4);
        }
      }


    }

    .task-container-content {
      display: flex;
      flex-direction: row;
      max-height: 50vh;
      box-sizing: content-box;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      overflow: hidden;

      .task-container-tasks {
        width: 100%;

        .header-buttonbar {
          width: 100%;
          padding: var(--bfs8);
          display: flex;
          border-bottom: 1px solid var(--bfc-base-dimmed);

          > div {
            width: 100%;

            > button {
              flex: 1;
            }

          }

        }

        .task-list {
          height: 50vh;
          overflow-y: scroll;
          position: relative;
          scrollbar-width: thin;

          > p {
            padding: var(--bfs4) var(--bfs12);
            margin: 0;
            background-color: var(--bfc-base-2);
            border-bottom: 1px solid var(--bfc-base-dimmed);
          }

          > div {
            margin-bottom: var(--bfs8);
          }

        }

      }

      .task-container-level {
        display: flex;
        flex-direction: column;
        border-left: 1px solid var(--bfc-base-dimmed);
        padding: var(--bfs16) var(--bfs12);
        min-width: 80px;
        width: 80px;

        > p {
          margin: 0;
          margin-top: var(--bfs8);
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: var(--bfc-base-c-2);
          text-align: center;
        }

      }


    }

  }

}
@media (max-width: 600px) {
  #task-container {
    max-height: 535px;
  }
}