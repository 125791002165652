#company-overview {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    width: 100%;

    .content-main {
      padding: var(--bfl-page-padding);
      width: 100%;


      .favorite-container {
        padding: var(--bfl-box-padding) 0;
        --bfl-autocol-width: 250px;
        gap: 24px;

      }

      .overview-table {

        > tbody {
          @media (min-width: 960px) {
            tr {
              > td {
                padding-top: 9px;
                padding-bottom: 9px;
              }
            }
          }

          .bf-table-row.bf-table-expand-closed {
            > td {
              padding-top: 9px;
              padding-bottom: 9px;
            }
          }

          tr:hover {
            background: var(--bfc-base-1);
          }

          .overview-collapsed-label {
            margin: var(--bfs16) 0;

            > p {
              margin: 0;

            }

          }

          .overview-favorite-checkbox {
            display: flex;
            flex-direction: row;
            background: var(--bfc-base-3);
            border: 1px solid var(--bfc-base-c-dimmed);
            border-radius: 3px;
            cursor: pointer;
            flex: 1;
            margin-bottom: var(--bfs8);

            > svg  {
              margin: auto var(--bfs12);
            }

            > p {
              margin: 9.5px;
            }


          }


        }

      }

    }

  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;

      .content-main {
        width: 80%;
      }
    }
  }
}

