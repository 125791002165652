#conditional-access {
  display: flex;
  width: 100%;

  .box {
    flex-direction: column;
  }

  .action-button {
    margin-top: var(--bfs24);
    display: flex;
    justify-content: flex-end;
    gap: var(--bfs16);
    margin-bottom: auto;
  }

  .policy-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-top: 32px;

    @media (max-width: 414px) {
      grid-template-columns: 1fr;
    }

    .style {
      background: var(--bfc-base-3);
      padding: var(--bfs4);
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .select-box {
        padding: 2px;
        background-color: var(--bfc-base-c-dimmed);

        .view-box {
          margin: 8px;
        }
      }
    }

  }

}

#form-group {
  display: flex;
  align-content: center;
  background-color: var(--bfc-base-3);
  padding: 4px 12px;
  border-radius: var(--bfs4);

  .form-header {
    display: flex;
    justify-content: space-between;
  }
}



