.onboard-task {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--bfs8);
  border-radius: 10px;
  background: var(--bfc-base-3);

  .task-text {
    display: flex;
    flex: 1;
    flex-direction: row;
    //border-bottom: 1px solid var(--bfc-base-3);

    .task-text-checkbox {
      display: flex;
      padding: var(--bfs12) var(--bfs16);

      > div {
        margin: auto;
      }

    }

    .task-text-content {
      flex: 1;

      .task-text-header {
        display: flex;
        flex-direction: row;

        > span {
          white-space: normal;
        }

        .open-guide {
          padding-right: var(--bfs12);
          margin-left: auto;

          > span {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--bfc-base-c-theme);
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            margin: auto 0;
            cursor: pointer;

            > p {
              margin: 0 0 0 var(--bfs8);
            }

          }

        }


      }

      .task-text-info {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: var(--bfs4);

        > div {
          display: flex;
          flex-direction: row;

          > div {
            display: flex;
            width: 20px;
            height: 20px;
            background-color: var(--bfc-base-1);
            border-radius: 50%;
            margin-right: var(--bfs4);

            > svg {
              color: var(--bfc-base-c-2);
              height: 18px;
              width: var(--bfs12);
              margin: auto;
            }

          }


          > span {
            margin-right: var(--bfs8);
            display: flex;
            margin-top: 0;
            margin-bottom: 0;
            justify-content: center;
            align-items: center;

            color: var(--bfc-base-c-1);

            > svg {
              margin-right: var(--bfs4);
            }

            > span {
              display: flex;
              margin-left: var(--bfs4);

              > img {
                height: 18px;
                width: 18px;
                border-radius: 50%;
                margin-right: var(--bfs4);
              }
            }
          }
        }

        > * {
          margin-right: var(--bfs12);
        }

        > p {
          margin-top: 0;
          margin-bottom: 0;
          justify-content: flex-start;
        }
      }

      @media (max-width: 760px) {
        .task-text-info {
          flex-direction: column;
        }
      }

    }



  }



}

.onboard-task:hover {
  background-color: var(--bfc-theme-c-1);
}