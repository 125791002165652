.backoffice-project-team-container {
  margin: 20px;

  .edit-header {
    display: flex;
    flex-direction: row;
    margin: var(--bfs16) 0;

    > * {
      margin: auto var(--bfs16) auto 0;
    }

  }

  .edit-footer {
    display: flex;
    justify-content: space-around;
    color: var(--bfc-base-c-theme);
    background: var(--bfc-base-2);
    border: 1px solid var(--bfc-base-dimmed);
    padding: var(--bfs8);
    cursor: pointer;
    border-radius: 3px;

    > div {
      display: flex;

      > svg {
        margin-right: var(--bfs8);
      }

      > * {
        margin: auto 0;
      }
    }

  }

  > h3 {
    color: var(--bfc-base-c-2);
  }

  .team-main-content {


  }

}



.team-row {
  background: var(--bfc-base-3);
  display: flex;
  flex-direction: row;
  padding: var(--bfs8) var(--bfs12);
  margin-bottom: var(--bfs8);
  border-radius: 3px;

  > * {
    margin: auto 0;
  }

  .team-row-separator {
    background: var(--bfc-base-dimmed);
    width: 1px;
    border-radius: 2px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 0 8px;
  }

  .team-row-name {
    flex-grow: 1;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      > * {
        margin: auto 0;
      }

    }

  }

  .team-row-badge {
    display: flex;
    flex: 1;

    > *:first-child {
      margin-right: auto;
    }

    > *:last-child {
      margin: auto 0;
    }

  }

  .member-photo-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    margin-right: var(--bfs8);

    > img {
      height: 24px;
      width: 24px;
      border-radius: 50%;
    }

  }

}

.team-edit-modal {
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .complexity-radio {

    > div {
      padding: var(--bfs4);
      display: flex;
      border: 1px solid var(--bfc-base-dimmed);

      > div {
        display: flex;
        flex-direction: column;
        text-align: center;

        > div {
          align-self: center;
        }

        > p {
          margin-top: 0;
        }


      }
    }

  }

  .bf-modal-content-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }


}
