
.billing-slider {
  flex: 1;

  > div {
    margin-top: var(--bfs16);
  }

  .edit-billing-input-btn {
    display: flex;

    > div {
      flex-grow: inherit;
    }

    > button {
      margin-top: auto;
      margin-left: var(--bfs4);

    }

  }
}


