.contact-team-card {
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  background-color: var(--bfc-base-3);
  margin-bottom: var(--bfs16);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--bfs16) var(--bfs24);

    > h5 {
      margin: 0;
    }

  }

  .card-body {
    border-top: 1px solid var(--bfc-base-dimmed);

    .team-member-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: var(--bfs24);
      border-bottom: 1px solid var(--bfc-base-dimmed);

      .img-container {
        display: flex;
        flex-direction: row;


        > img {
          border: 1px solid var(--bfc-base-c-dimmed);
          height: 42px;
          width: 42px;
          border-radius: 50%;
          margin-right: var(--bfs12);
        }

        .contact-info-box {
          display: flex;
          flex-direction: column;

          > p {
            margin: 0;
          }

        }
      }

      .contact-info {
        display: flex;
        flex-direction: row;

        > span {
          display: flex;
          color: var(--bfc-base-c-2);
          margin-right: var(--bfs8);
          align-items: flex-end;

          > svg {
            margin-right: var(--bfs8);
            font-size: 15px;
            margin-bottom: 3px;
          }

          > p {
            margin: 0;
          }
        }

        > a {
          display: flex;
          color: var(--bfc-base-c-2);
          margin-right: var(--bfs8);
          align-items: flex-end;

          > svg {
            margin-right: var(--bfs8);
            font-size: 15px;
            margin-bottom: 3px;
          }

          > p {
            margin: 0;
          }
        }

      }
      @media (max-width: 960px) {
        .contact-info {
          margin-left: 54px;
        }

      }
      @media (max-width: 600px) {
        .contact-info {
          flex-direction: column;
        }

      }


    }

    @media (max-width: 960px) {
      .team-member-row {
        flex-direction: column;
      }

    }

    // Project team card
    .card-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: var(--bfs24);

      .card-text-header {
        display: flex;
        flex-direction: row;
        margin-bottom: var(--bfs16);

        > div:first-of-type {
          height: 42px;
          width: 42px;
          border-radius: 50%;
          border: 1px solid var(--bfc-base-c-dimmed);
          margin-right: var(--bfs12);
          background: var(--bfc-base-1);
          display: flex;

          > img {
            margin: auto;
            height: 21px;
            width: 21px;
          }
        }


        > div:last-of-type {
          display: flex;
          flex-direction: column;

          > p {
            margin: 0;
          }

        }
      }


    }

    .card-image {
      position: absolute;
      right: 0;
      top: 74px;
      overflow: hidden;
      width: 110px;
      height: 130px;
      border-bottom-right-radius: 11px;

      > img {
        height: 150px;
      }

    }


  }

  .card-body.project-team {
    border-bottom: 0;
  }

  .card-footer {
    display: flex;
    //justify-content: center;
    padding: var(--bfs12);
    background-color: var(--bfc-base-2);
    border-radius: 0 0 11px 11px;

    > button {
      flex: 1;
    }

  }

}