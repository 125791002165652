.network-progress-box {
  border: 1px solid var(--bfc-base-dimmed);

  .copy-row {
    border-bottom: 1px solid var(--bfc-base-dimmed);
    padding: var(--bfs6) var(--bfs12);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--bfc-base-2);
    align-items: center;

    > p {
      margin: 0;
    }
  }

  > span {
    border-bottom: 1px solid var(--bfc-base-dimmed);
    padding: var(--bfs12);
    background-color: var(--bfc-base-2);
    display: flex;flex-direction: row;
    align-items: center;

    > p {
      margin: 0;
    }

    > div {
      margin-left: var(--bfs8);
    }

  }
  .box-stepbar {
    background: var(--bfc-base-3);
    padding: var(--bfs24) var(--bfs16);



    .bf-stepbar-circle > .bf-stepbar-item .bf-stepbar-circle-icon {
      border-color: var(--bfc-base-c-wcag);
      background: var(--bfc-base);
    }

    .bf-stepbar-circle > .bf-stepbar-item-active .bf-stepbar-circle-icon {
      border-color: var(--bfc-base-c-theme);
      background: var(--bfc-theme);

      > svg {
        position: relative;
        color: var(--bfc-base);
        z-index: 2;
      }
    }

    .bf-stepbar-circle > .current-half-checked .bf-stepbar-circle-icon {
      background: var(--bfc-base);

      > svg {
        position: relative;
        color: var(--bfc-base);
        z-index: 0;
      }
    }

    .bf-stepbar-circle > .bf-stepbar-item-active ~ ::after {
      background: var(--bfc-theme);
    }

    .bf-stepbar-circle > .bf-stepbar-item .next-checked::after {
      background: var(--bfc-theme);
    }

    .bf-stepbar-circle > .bf-stepbar-item-active::after {
      background: var(--bfc-theme);
    }

    .bf-stepbar-circle > .prev-checked::after {
      background: var(--bfc-base-c-wcag);
    }

    .bf-stepbar-circle > .current-checked::after {
      background: linear-gradient(90deg, var(--bfc-base-c-theme) 50%, var(--bfc-base-c-wcag) 50%);
    }

    .bf-stepbar-circle > .last-element::after {
      background: var(--bfc-theme);
    }


  }

  .box-details {
    background: var(--bfc-base-2);
    border-top: 1px solid var(--bfc-base-dimmed);
    padding: var(--bfs16);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > p {
      margin: 0;
    }

    .app-details {
      width: 100%;
      max-height: 120px;
      overflow-x: auto;
    }

    .domain-details {
      width: 100%;
      max-height: 120px;
      overflow-x: auto;
    }

    .box-dates {
      display: flex;
      flex-direction: row;

      > p {
        margin: 0 0 0 var(--bfs24);

        > span {
          color: var(--bfc-base-c-2);
        }

      }

    }

  }


}