.user-row {

  .user-row-message {
    padding: var(--bfs8);
  }


  td {
    padding-top: var(--bfs4);
    padding-bottom: var(--bfs4);
  }

  td:first-child {


    div:first-child {

    }

  }


}

.user-row-disabled {

  td {
    color: var(--bfc-base-c-2);
    background-color: var(--bfc-base-2)!important;
  }

  span {
    color: var(--bfc-base-c-2);
  }

}