#overview-articles {
  margin-top: var(--bfs40);
  --bfl-columns: 3;
  --bfl-gap: var(--bfs24);
  margin-bottom: var(--bfs40);

  @media (max-width: 600px) {
    margin: 16px;
  }

  .overview-article {
    border-radius: var(--bfs12);
    box-shadow: 0px 4px 8px 0px var(--bfc-shadow);

    > a {
      overflow: hidden;
      border-top-left-radius: var(--bfs12);
      border-top-right-radius: var(--bfs12);
      
      .image-box {
        overflow:  hidden; // to keep the image within the box when hovering
        object-fit: initial;
      }


      .bf-card-image {
        object-fit: cover;
        max-width: 100%;
        height: auto;
        max-height: 340px;
        flex: 1;
        transition: transform 0.4s ease;
        border-top-left-radius: var(--bfs12);
        border-top-right-radius: var(--bfs12);
      }

      .bf-card-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        color: var(--bfc-base-c-1);
        padding: 0px;
        margin: 0px;
      }

      .bf-card-content {
        padding: 0px;
        margin-bottom: 0px;
        font-size: var(--bf-font-size-m);
        font-weight: 400;
      }
    }
    .card-content {
      padding: var(--bfs24);
      > span {
        > svg {
          color: var(--bfc-base-c-2);
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .content-container {
      display: flex;
      flex-direction: column;
      flex: 1 2;
      text-align: center;
      background-color: var(--bfc-base-3);
      justify-content: center;
      align-items: center;
      margin: 0px;

      > div {
        > h2 {
          margin-top: 0;
          color: var(--bfc-base-c-1);
        }

        > h4 {
          margin-top: 0;
          color: var(--bfc-base-c-1);
        }

        > p {
          margin-bottom: 0;
        }
      }
    }
  }

  .overview-article:hover {
    > a {
      .bf-card-image {
        transform: scale(1.06);
      }
    }
    .content-container {
      > div {
        > h2 {
          text-decoration: underline;
        }

        > h4 {
          text-decoration: underline;
        }
      }
    }
  }

  .overview-article:focus {
    outline: none;
  }

  .overview-article:focus-visible {
    outline: 1px dashed var(--bff-theme);
    -moz-outline-radius: var(--bfl-border-radius);
    /* make sure the outline is visible on top of expanded accordion item content */
    position: relative;
    z-index: 1;
  }

  @media (max-width: 650px) {
    .overview-article {
      flex-direction: column;
      margin-bottom: var(--bfs16);
      max-height: 600px;
    }
  }
}

@media (max-width: 600px) {
  #overview-articles {
    --bfl-columns: 1;
  }
}
