#computer-customization {

  .content {
    display: flex;

    .content-main {
      padding: var(--bfl-page-padding);

      .computer-customization-box {
        display: flex;
        flex-direction: column;

        .accordion-box {

          .accordion-default-content {
            display: flex;
            flex-direction: column;
            padding: var(--bfs16);
            padding-top: 0;

            > p:first-child {
              margin-top: 0;
              margin-bottom: 0;
            }

            .accordion-switch-line {
              --bfl-columns: 3;
              align-items: center;
              border-bottom: 1px solid var(--bfc-base-dimmed);

              > p:first-of-type {
                color: var(--bfc-theme);
              }

              > p {
                color: var(--bfc-base-c-2);
              }

              > div {
                display: flex;
                justify-content: flex-end;
              }


            }

            .accordion-switch-line:last-of-type {
              border-bottom: 0;
            }


          }

        }

        .accordion-box .office-box {
          .bf-accordion-item-content {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

          }
        }

        .content-main-search {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            margin-top: var(--bfs40)
          }

        }
      }


    }
  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        padding: var(--bfs40) 0;
        width: 80%;
      }
    }
  }

}