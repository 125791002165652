
#tasks {

  .test {
    > div {
      min-height: 100%;
    }

    #drawer-publish-article {
      min-height: calc(100% - 210px);
    }
  }

  .content {
    display: flex;
    min-height: calc(100vh - 56px);
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;

    .content-main {
      width: 100%;
      flex: 1;

      .content-main-header {
        background-repeat: no-repeat;
        margin-bottom: var(--bfs16);
        border-radius: 15px;

        > * {
          margin: 0;
          color: var(--bfc-base-1);
        }

        .topbar-collaborate {
          height: 100%;
          display: flex;

          .settings-card {
            transition: all 0.3s ease 0s;
            background-color: var(--bfc-base-3);
            border-radius: 10px;
            display: flex;
            flex: 1;
            flex-direction: row;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            cursor: default;

            > p {
              margin: 0;
              color: var(--bfc-base-c);
            }

            > span {
              display: flex;
              justify-content: space-between;
              color: var(--bfc-base-c);

              > p {
                margin: 0;
              }

            }

            .settings-button-images {
              padding: var(--bfs16);
              display: flex;
              width: 50%;
              flex-direction: column;
              background: var(--bfc-base-3);
              border-radius: 11px 0 0 11px;

              > span {
                margin-bottom: var(--bfs4);

                > img {
                  height: 18px;
                  width: 18px;
                  border-radius: 50%;
                  border: 1px solid var(--bfc-base-dimmed);

                }

                > p {
                  margin: 0;
                  margin-left: var(--bfs4);
                  color: var(--bfc-base-c);
                }

              }

              .icon-span {
                display: flex;
                align-items: center;
                border-radius: 50%;
                background-color: var(--bfc-base-dimmed);
                height: 18px;
                width: 18px;
                justify-self: center;
                align-self: center;
                border: 1px solid var(--bfc-base-c-2);

                > svg {
                  margin: auto;
                  border-radius: 50%;
                  color: var(--bfc-base-c-2);
                }

              }

            }


          }

          .invite-text {
            padding: var(--bfs16);
            width: 50%;
            background: var(--bfc-base-2);
            border-radius: 0 11px 11px 0;
            color: var(--bfc-base-c-1);
            cursor: pointer;

            > h3 {
              margin: 0;
            }

            > h4 {
              margin: 0;
              text-align: end;
            }

          }

          .invite-text:hover {
            > h3 {
              text-decoration: underline;
            }
          }

          .highlighted {
            border: solid var(--bfc-attn) 2px;
            transform: scale(1.05);
          }

        }

        .header-task {
          cursor: pointer;

          .header-task-image {
            padding: var(--bfs16);
            border-radius: 10px 10px 0 0;
            background-repeat: round;

            > p:first-of-type {
              margin-top: var(--bfs12);
              margin-bottom: 0;
            }

            > h5 {
              margin: 0;
            }

            .header-icon {
              display: flex;
              width: 36px;
              height: 36px;
              background: rgba(250, 250, 250, 0.3);
              border-radius: 50%;

              > svg {
                margin: auto;
                width: 13px;
                height: 21px;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
              }

            }

          }

          .header-task-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: var(--bfs8);
            background-color: var(--bfc-base-3);
            border-radius: 0 0 10px 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

            > span {
              display: flex;
              margin-top: 0;
              margin-bottom: 0;
              justify-content: center;
              align-items: center;

              color: var(--bfc-base-c-1);

              > svg {
                margin-right: var(--bfs4);
              }

              > span {
                display: flex;
                margin-left: var(--bfs4);

                > img {
                  height: 18px;
                  width: 18px;
                  border-radius: 50%;
                  margin-right: var(--bfs4);
                }
              }
            }

            > * {
              margin-right: var(--bfs12);
            }

            > p {
              margin-top: 0;
              margin-bottom: 0;
              justify-content: flex-start;
            }

          }

          > p {
            margin: 0;
          }

        }

        .header-task:hover {

          .header-task-image {

            > h5 {
              text-decoration-line: underline;
              -moz-text-decoration-line: underline;
            }

          }

        }


      }

    }

    .overview-bottom-bar {
      margin-top: auto;
    }

  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        width: 80%;
        align-self: center;
      }
    }
  }

}
