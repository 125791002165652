.global-settings-page {
  padding: var(--bfl-page-padding);

  .global-filter-row {
    margin-top: var(--bfs24);
    display: flex;
    justify-content: space-between;

    > h3 {
      margin: 0
    }

    .filter-btn-row {
      display: flex;
      align-items: flex-end;

      > * {
        min-width: 200px;
        margin-right: var(--bfs8);
      }

    }

    .filter-search-row {
      display: flex;
      align-items: flex-start;

      > * {
        min-width: 200px;
        margin-right: var(--bfs8);
      }

    }

  }
}


#global-task-settings {


}