#hub {
  position: relative;
  height: 100%; 
  display: flex;
  background-color: var(--bfc-base-3);
  overflow: hidden;
  
  /* wrapper for current page contents */
  #hub-container {
    // grid-area: content;
    flex: 1;
    overflow-y: scroll;
  
    /* the actual page, with page header and content */ 
    > div:first-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      
    }

  }
}
.content {
  display: flex;
  width: 100%;

  .content-full {
    width: 100%;
    margin: var(--bfl-page-padding);
  }

  .content-main {
    padding: var(--bfl-page-padding);
    width: 100%;
  }

}
@media (min-width: 1921px) {
  .content {
    justify-content: space-around;
    .content-main {
      width: 80%;
    }
  }
}

.table-size-dropdown {
  min-width: 100px;
}

.bf-drawer {

  > div {

    .bf-drawer-content {

      > header {
        margin-bottom: var(--bfs40);
      }

    }


    .bf-drawer-footer {
      background-color: var(--bfc-base-2);
      border-top: 1px solid var(--bfc-base-dimmed);
      padding-top: var(--bfs24);
      padding-bottom: var(--bfs24);
    }

  }
}

.drawer-bottom-button-row {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
  justify-content: space-between;

  .button-row-collection {

    > :first-child {
      margin-right: var(--bfs16);
    }

  }

}