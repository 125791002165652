
.location-system-accordion {

  .location-system-accordion-header {

    .header-files {
      border-radius: 3px;
      background-color: var(--bfc-base-3);
      border: 1px dashed var(--bfc-base-dimmed);
      padding: var(--bfs12);
      margin-bottom: var(--bfs12);
      flex: 1;

      > p {
        margin-bottom: 0;
        margin-top: var(--bfs8);
      }

      > span {
        margin-right: var(--bfs16);

        > svg {
          color: var(--bfc-alert);
          cursor: pointer;
        }

        .documentation {
          font-size: var(--bf-font-size-m);
          margin-right: var(--bfs8);
          color: var(--bfc-base-c-1);
          display: inline;
        }
      }
    }

    .header-contacts {
      display: flex;
      flex-direction: row;
      padding: var(--bfs8) 0;
      border-top: 1px solid var(--bfc-base-dimmed);
      border-bottom: 1px solid var(--bfc-base-dimmed);

      .drawer-contact-list-contact {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--bfs8);

        > span:first-of-type {
          > svg {
            margin-left: var(--bfs12);
            color: var(--bfc-alert);
            cursor: pointer;
          }

          > p {
            margin: 0 var(--bfs4) 0 var(--bfs4);
          }
        }

        > span {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--bfc-base-c);

          > svg {
            margin: var(--bfs4);
            color: var(--bfc-base-c-2);
          }

        }

      }

    }

  }

  .meeting-room-floor {
    background-color: var(--bfc-base-1);
    border: 1px solid var(--bfc-base-dimmed);
    border-radius: 11px;
    margin-bottom: var(--bfs12);

    .meeting-room-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: var(--bfs8) var(--bfs12);
      border-bottom: 1px solid var(--bfc-base-dimmed);

      > p {
        margin: 0;
      }


    }

    .meeting-room-floor-content{
      padding: var(--bfs8) var(--bfs12);

      .meeting-room-floor-content-item {


        > p {
          margin: 0;
        }

        > p:last-of-type {
          color: var(--bfc-base-c-2);
        }

        > * {
          margin-bottom: var(--bfs4);

          > div {

            > svg {
              margin-right: var(--bfs4);
            }

            > img {
              margin-right: var(--bfs4);
            }

          }

        }

        > *:last-of-type {
          margin-bottom: 0;
        }

      }


    }


  }

  .meeting-room-floor:last-of-type {
    margin-bottom: 0;
  }

  .location-system-accordion-contact {
    --bfl-columns: 2;
    border-radius: 3px;
    background-color: var(--bfc-base-3);
    border: 1px solid var(--bfc-base-dimmed);
    padding: var(--bfs12);
    margin-bottom: var(--bfs12);
    margin-left: var(--bfs12);

    > div {
      > p {
        margin: 0;
      }
    }


  }



  .location-system-accordion-content {
    margin-top: var(--bfs12);
    --bfl-columns: 3;

    .location-system-accordion-item {
      display: flex;
      flex-direction: row;
      background-color: var(--bfc-base-3);
      padding: var(--bfs8) var(--bfs12);

      > div:first-of-type {
        flex: 1;
      }

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        > span {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: auto;

          > svg {
            margin-right: var(--bfs4);
          }

          > * {
            margin: 0 auto;
          }

        }

        > svg {
          margin-right: var(--bfs4);
          color: var(--bfc-base-c-2);
        }

        > p {
          margin: 0;
        }

        .separator {
          margin: 0 var(--bfs12);
          width: 1px;
          background-color: var(--bfc-base-dimmed);
          height: 21px;
        }

      }

    }

  }
  @media (max-width: 1280px) {
    .location-system-accordion-content {
      --bfl-columns: 2;
    }
  }
  @media (max-width: 960px) {
    .location-system-accordion-content {
      --bfl-columns: 1;
    }
  }

}

.location-system-accordion-modal {
  min-height: 400px;
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
      --bfl-columns: 2;
    }

    > div:last-of-type {
      margin-top: var(--bfs8);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

}
