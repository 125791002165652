.global-loader {
  position: absolute;
  display: inline-block;
  bottom: var(--bfs40);
  right: var(--bfs40);

  .loading-spinner {
    color: var(--bfc-theme-3);
    font-size: var(--bfs40);
  }
}