.overview-favorite-card{
  background-color: var(--bfc-base-3);
  height: 120px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  .card-header {
    display: flex;
    flex-direction: column;
    padding: var(--bfs12);
    border-bottom: 1px solid var(--bfc-base-dimmed);


    > div:first-of-type {
      display: flex;
      flex-direction: row;

      > p {
        margin: auto 0;
      }
      > svg {
        margin: auto var(--bfs8) auto 0;
      }

    }

  }

  .card-body {
    display: flex;
    flex-direction: row;
    flex: 1;

    > div {
      display: flex;
      height: 100%;
      width: 100%;

      > a {
        margin: auto;
      }
    }

    > div:first-child {
      border-right: 1px solid var(--bfc-base-c-dimmed);
    }

  }


}