.filter-row {
  display: flex;
  justify-content: space-between;

  .filter-btn-row {
    display: flex;
    align-items: flex-end;

    > * {
      min-width: 200px;
      margin-right: var(--bfs8);
    }

  }

  .filter-search-row {
    display: flex;
    align-items: flex-start;

    > * {
      min-width: 200px;
      margin-right: var(--bfs8);
    }

  }

  .workplace-table {
    margin-top: var(--bfs8);


  }

}