.billing-box-item {
  background: var(--bfc-base-3);
  padding: var(--bfs12) var(--bfs24);
  border: 1px solid var(--bfc-base-c-dimmed);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  word-break: break-word;


  .billing-box-item-grid {
    --bfl-columns: 3;
    width: 100%;
  }
  @media (min-width: 960px) {
    .billing-box-item-grid {
      --bfl-columns: 3;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin-top: var(--bfs4);
    color: var(--bfc-base-c-2);
  }

  h5 {
    margin: 0;
  }


}