.new-step-app {
  display: flex;
  flex-direction: column;
  width: 100%;

  .new-step-app-header {
    background-color: var(--bfc-base-2);
    display: flex;
    padding: var(--bfs40);
    flex-direction: column;
    justify-content: space-around;
    width: 100%;

    .new-app-progress {
      max-width: 984px;
      display: flex;
      flex-direction: column;

      > div {
        flex: 1;
      }

    }

    > div {
      width: 100%;
      margin: auto;

      > p {
        margin-bottom: 0;
        margin-top: 0;
      }

    }

    > p:first-of-type {
      color: var(--bfc-base-c-theme);
      cursor: pointer;
      margin: 0;
    }

  }

  .new-step-app-body {
    padding: var(--bfs40);
    justify-content: center;
    display: flex;
    padding-top: var(--bfs24);

    > div {
      display: flex;
      flex-direction: column;
      margin-right: 0;
      justify-content: center;
      width: 100%;

    } @media (min-width: 960px) {
      > div {
        flex-direction: row;
      }
  }

}

}

.content-main {

  .edit-application-form {
    width: 100%;

    .edit-application-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > h2 {
        margin-top: 0;
        margin-bottom: var(--bfs24);
      }

      .button-row {
        display: flex;
        margin-top: 0;
        margin-bottom: auto;
        justify-content: space-between;

        > button {
          min-width: 140px;
          margin-right: var(--bfs16);
        }

        > div {

          > :last-child {
            margin-right: 0;
          }

          > button {
            min-width: 140px;
            margin-right: var(--bfs16);
          }
        }


      }

    }

    .edit-application-app-top-container {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .edit-application-app {
      //background-color: var(--bfc-base-2);
      //padding: var(--bfs24);

      .edit-application-app-top {
        width: 100%;
        --bfl-columns: 1;
      }
      @media (min-width: 600px) {
        .edit-application-app-top {
          --bfl-columns: 2;
        }
      }
      @media (min-width: 960px) {
        .edit-application-app-top {
          --bfl-columns: 3;
        }
      }
      @media (min-width: 1280px) {
        .edit-application-app-top {
          --bfl-columns: 4;
        }
      }
      @media (min-width: 1600px) {
        .edit-application-app-top {
          --bfl-columns: 5;
        }
      }

      .edit-application-app-documentation {
        padding: var(--bfs8);
        height: 182px;
        min-width: 300px;
        overflow: auto;
        background-color: var(--bfc-base-2);

        > span {
          margin-bottom: var(--bfs12);
          display: flex;
          justify-content: space-between;

          > span {
            display: flex;

            > svg {
              margin-top: auto;
              margin-bottom: var(--bfs2);
            }

            > p {
              margin: 0 0 0 var(--bfs4);
              color: var(--bfc-base-c-1);
            }

          }

        }
      }


      .edit-application-app-bottom {
        margin-top: var(--bfs12);
        --bfl-columns: 1;

        .application-file-upload {
          margin-top: var(--bfs24);

          > label {
            margin: 0;
          }

          > p {
            margin: 0 0 var(--bfs4);
          }

          > div {

            .application-file-list {
              background-color: var(--bfc-base-2);
              padding: var(--bfs12);
              height: 182px;
              overflow: scroll;

              > span {
                margin-bottom: var(--bfs12);
                display: flex;
                justify-content: space-between;

                > span {
                  display: flex;

                  > svg {
                    margin-top: auto;
                    margin-bottom: var(--bfs2);
                  }

                  > p {
                    margin: 0 0 0 var(--bfs4);
                    color: var(--bfc-base-c-1);
                  }

                }

                > svg {
                  color: var(--bfc-base-c-alert);
                }

              }

            }

          }

        }


      }
      @media (min-width: 600px) {
        .edit-application-app-bottom {
          --bfl-columns: 2;
        }
      }

    }

    .contacts-container {
      margin-top: var(--bfs40);
      --bfl-columns: 1;
      --bfl-gap: var(--bfs32);

      .application-integration-box {
        flex: 3;

        > p {
          color: var(--bfc-base-c-2);
          margin: var(--bfs8) 0;
        }

        .box-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > h3 {
            margin: 0;
          }
        }

        .box-content {
          display: flex;
          flex-direction: column;

          > p {
            margin: 0 0 var(--bfs8);
          }

          .integration-item-content:first-of-type {
            margin-top: 0;
            margin-bottom: var(--bfs4);
          }

          .integration-item-content {
            display: flex;
            margin: var(--bfs4) 0;
            flex-direction: row;
            align-items: center;
            width: 100%;

            > img {
              width: 18px;
              height: 18px;
              margin-right: var(--bfs6);
            }

            > div {
              display: flex;
              flex-direction: row;
            }

            .integration-item-content-text {
              padding: 0 var(--bfs16);
              background: var(--bfc-base-3);
              border: 1px solid var(--bfc-base-c-dimmed);
              display: flex;
              width: 100%;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              > svg {
                cursor: pointer;
                color: var(--bfc-alert);
              }
            }
          }

        }


      }
    }
    @media (min-width: 1279px) {
      .contacts-container {
        --bfl-columns: 3;
      }
    }

  }
}
@media (min-width: 1921px) {
  .edit-application {
    justify-content: space-around;
    .edit-application {
      width: 80%;
    }
  }
}