.editor-card {
  display: flex;
  margin-bottom: var(--bfs8);
  padding: var(--bfs8);
  flex-direction: row;
  min-width: 212px;
  align-items: center;

  border-radius: 11px;
  border: 1px solid var(--bfc-base-dimmed);
  background: var(--bfc-base-3);

  > p {
    margin: 0 auto;

    > svg {
      margin-left: var(--bfs8);
    }

  }

  .editor-card-footer {
  margin-top: auto;

    > p {
      color: var(--bfc-base-c-2);
      margin: 0;
    }
  }



  .editor-card-image {
    display: flex;
    height: 21px;
    width: 21px;
    border-radius: 3%;

    > img {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: var(--bfs12);
      height: 21px;
      width: 21px;
      border-radius: 50%;
    }

    > div {
      background-color: var(--bfc-base-dimmed);
      border-radius: 50%;
      height: 21px;
      width: 21px;
      display: flex;

      > svg {
        margin: auto;
        color: var(--bfc-base-c-2);
        height: var(--bfs16);
        width: var(--bfs16);
      }
    }

    .user-role-icon {
      background: var(--bfc-base-3);
      position: relative;
      right: 20px;
      align-self: flex-end;
      padding: 3px;
      width: var(--bfs16);
      height: var(--bfs16);
      display: flex;
      border-radius: 50%;
      margin: 2px;

      > svg {
        margin: auto;
        height: 10px;
        width: 10px;
        color: var(--bfc-base-c-theme);
      }

    }

  }

  .editor-card-text {
    margin-left: var(--bfs8);
    flex: 1;

    > p {
      margin: 0;
    }

    > span {
      margin: 0;
      display: flex;

      > p {
        margin: 0;
        align-self: center;
      }

      > p:first-of-type {
        margin-right: var(--bfs4);
      }

      > span {
        margin-left: auto;
      }
    }

  }

  .editor-card-adder {
    color: var(--bfc-base-c-theme);
    text-align: center;
    margin: var(--bfs8) auto;
    display: flex;
    flex-direction: row;

    > * {
      margin: auto 0;
    }

    > *:first-child {
      margin-right: var(--bfs4);
    }

  }


}

.editor-card:hover {
  cursor: pointer;
  background: var(--bfc-base-1);
}

.editor-card:focus {
  cursor: pointer;
  background: var(--bfc-base-1);
}