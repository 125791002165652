
.google-map-code {
  position: relative;
  display: flex;
  flex: 1;

  > iframe {
    flex: 1;
    width: 100%;
  }

  .simple-button-uploader {
    z-index: 9999;
    position: absolute;
    top: 0;
    right: 0;
    margin: var(--bfs4);
  }

}