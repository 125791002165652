.box-content.bfl-grid {
  --bfl-columns: 3;
  --bfl-gap: var(--bfs24);
  flex-direction: row;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin: 16px;
  }

  > div:last-of-type {
    margin-right: 0;
  }

  .task-box {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    padding: 24px;
    border-radius: var(--Border-radius-M, 12px);
    background: var(--bfc-base-3);
    gap: 12px;
    flex: 1 0 0;
    box-shadow: 0px 4px 8px 0px var(--bfc-shadow);

    .icon-box {
      display: flex;
      width: 50px;
      height: 50px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: var(--Border-radius-Full, 9999px);
      background: var(--bfc-attn-c);

      &.checked {
        color: var(--bfc-success-fade-c);
        background: var(--bfc-success-fade);
    
        > svg {
          color: currentColor;
        }
      }

      &.hourglass {
        color: var(--bfc-warning-c);
        background: var(--bfc-warning);
    
        > svg {
          color: currentColor;
        }
      }

      > svg {
        color: var(--bfc-attn);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }

    .icon-box-chill {
      background: var(--bfc-chill-c);
      > svg {
        color: var(--bfc-chill);
      }
    }

    .task-text {
      display: flex;
      flex-direction: column;
      flex: 1;

      > span {
        > h5 {
          margin-right: var(--bfs4);
        }
      }

      > p {
        color: var(--bfc-base-c-2);
      }
    }

    > p {
      margin-top: var(--bfs4);
      margin-bottom: var(--bfs4);
    }

    .settings-button {
      display: flex;
      margin-top: auto;

      > button {
        flex: 1;
      }
    }

    > button {
      margin-top: auto;
    }

    > a {
      margin-top: auto;
    }

    h5 {
      font-weight: 740;
      font-size: 18px;
      line-height: 136%;
      margin: 0;
    }

    > div {
      display: flex;
      flex-direction: row;

      > span {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
      }

      > p {
        margin: 0;
      }

      > span {
        display: flex;
        flex: 1;
        justify-content: space-between;

        > p {
          margin: 0;
        }

        > svg {
          color: var(--bfc-base-c-2);
        }
      }
    }
  }

  @media (max-width: 600px) {
    .task-box {
      flex-direction: column;

      > div {
        > span {
          flex-direction: column;
          align-items: flex-start;

          > svg {
            margin-top: var(--bfs12);
          }
        }
      }
    }
  }

  .task-box:hover {
    .task-text {
      > span {
        text-decoration: underline;
      }
    }
  }

  .task-box:focus {
    outline: none;
  }

  .task-box:focus-visible {
    outline: 1px dashed var(--bff-theme);
    -moz-outline-radius: var(--bfl-border-radius);
    /* make sure the outline is visible on top of expanded accordion item content */
    position: relative;
    z-index: 1;
  }
}

@media (max-width: 960px) {
  .box-content.bfl-grid {
    --bfl-columns: 2;
  }
}
