.replacement-dropdown {
  max-width: 360px;
  position: relative;
  background: var(--bfc-base-3);

  .replacement-dropdown-input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    padding: 0 var(--bfs8);
    background-color: var(--bfc-base-2);
    border-color: hsl(0, 0%, 80%);
    border-radius: var(--bfl-border-radius);
    border-style: solid;
    border-width: 1px;
    cursor: text;
    min-height: var(--bfs40);
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    border: 0;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--bfc-base-c-wcag);


    .dropdown-icon {
      display: flex;
      color: var(--bfc-theme-1);
      margin: 0 0 0 auto;
    }

    > div {
      margin: 2px;
    }

  }


  .replacement-dropdown-input:focus {
    box-shadow: 0 0 0 1px var(--bfc-theme-3);
    border-bottom-color: var(--bfc-theme-3);
  }
  .replacement-dropdown-input:active {
    box-shadow: 0 0 0 1px var(--bfc-theme-3);
    border-bottom-color: var(--bfc-theme-3);
    // border-radius: var(--bfl-border-radius) var(--bfl-border-radius) 0 0;
  }

  .replacement-dropdown-input:focus:hover {
    border-bottom-color: var(--bfc-theme-3);
  }
  .replacement-dropdown-input:active:hover {
    border-bottom-color: var(--bfc-theme-3);
    // border-radius: var(--bfl-border-radius) var(--bfl-border-radius) 0 0;
  }

  .replacement-dropdown-body {
    position: absolute;
    width: 100%;
    padding: var(--bfs8) var(--bfs12);
    background: var(--bfc-base-3);
    z-index: 9999;
    box-shadow: 0 0 0 1px var(--bfc-theme-3);
    border-radius: 0 0 var(--bfl-border-radius) var(--bfl-border-radius);
    display: flex;
    flex-direction: column;

    .upgrade-os-switch {

      > label {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

    }

    > div {
      margin: var(--bfs8) 0;
      display: flex;
    }

    > p {
      color: var(--bfc-base-c-2);
      margin: 0;
      margin-top: var(--bfs8);
    }

    > p:first-of-type {
      margin: 0;
    }

  }

  .active-replacement-dropdown {
    box-shadow: 0 0 0 1px var(--bfc-theme-3);
    border-radius: var(--bfl-border-radius) var(--bfl-border-radius) 0 0;
  }

  .inactive-replacement-dropdown:hover {
    border-bottom-color: var(--bfc-base-c-inverted-2);
    border-radius: var(--bfl-border-radius);
  }

}