#edit-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;

  .add-users-top-bar {
    display: flex;
    padding: var(--Spacing-5, 40px);
    flex-direction: column;
    align-items: center;

    align-self: stretch;
    background: var(--bfc-base-2);

    > div {
      display: flex;
      gap: 12px;
      flex-direction: column;
      align-items: flex-start;
      max-width: 772px;
      width: 100%;

      > h1 {
        margin: 0;
      }

      > p {
        margin: 0;
      }
    }


  }

  .add-users-content {
    display: flex;
    max-width: 772px;
    flex-direction: column;
    align-items: flex-start;
    // gap: 24px;
    align-self: stretch;
    margin: 0 auto;
    padding-bottom: var(--bfs40);
    width: 100%;

    .add-user-header-container {
      border-radius: var(--Border-radius-M, 12px);
      background: var(--bfc-base-3);
      display: flex;
      flex-direction: column;
      box-shadow: var(--Colors-Effect-variables-Shadows-Axis-None, 0px) var(--Colors-Effect-variables-Shadows-Axis-XS, 4px) var(--Colors-Effect-variables-Shadows-Blur-S, 8px) var(--Colors-Effect-variables-Shadows-Spread-None, 0px) var(--Colors-Effect-variables-Shadows-Opacity-100, rgba(0, 0, 0, 0.10));

      .add-user-header {
        padding: var(--Spacing-2, 16px) var(--Spacing-3, 24px);
        border-bottom: 1px solid var(--bfc-base-dimmed);

        > h5 {
          margin: 0;
        }

      }

      .add-user-header-content {
        padding: var(--Spacing-3, 24px);

        > p {
          margin: 0;
          margin-bottom: var(--bfs8);
        }

        > div {
          --bfl-columns: 3;
        }

      }




    }

    .add-user-content {
      border-top-left-radius: var(--Border-radius-M, 12px);
      border-top-right-radius: var(--Border-radius-M, 12px);
      box-shadow: var(--Colors-Effect-variables-Shadows-Axis-None, 0px) var(--Colors-Effect-variables-Shadows-Axis-XS, 4px) var(--Colors-Effect-variables-Shadows-Blur-S, 8px) var(--Colors-Effect-variables-Shadows-Spread-None, 0px) var(--Colors-Effect-variables-Shadows-Opacity-100, rgba(0, 0, 0, 0.10));
      background: var(--bfc-base-3);
      display: flex;
      flex-direction: column;
      width: 100%;

      .add-user-method {
        display: flex;
        padding: var(--Spacing-2, 16px) var(--Spacing-3, 24px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-bottom: 1px solid var(--bfc-base-dimmed);
      }

      .add-user-method-description {
        display: flex;
        padding: var(--Spacing-3, 24px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--Spacing-3, 24px);
        align-self: stretch;

        .manual-user-import {
          width: 100%;

          .manual-user-import-required {
            --bfl-columns: 2;
            --bfl-gap: 0 var(--bfs12, 16px);
          }


          .add-user-button-expand {
            margin-top: var(--bfs12);

            .manual-user-import-optional {
              --bfl-columns: 2;
              margin-top: var(--bfs12);
            }

          }

        }


      }


    }
    .add-user-method-footer {
      display: flex;
      padding: var(--Spacing-2, 16px) var(--Spacing-3, 24px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-top: 1px solid var(--bfc-base-dimmed);
      background: var(--bfc-base-3);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      box-shadow: var(--Colors-Effect-variables-Shadows-Axis-None, 0px) var(--Colors-Effect-variables-Shadows-Axis-XS, 4px) var(--Colors-Effect-variables-Shadows-Blur-S, 8px) var(--Colors-Effect-variables-Shadows-Spread-None, 0px) var(--Colors-Effect-variables-Shadows-Opacity-100, rgba(0, 0, 0, 0.10));

      > div {
        display: flex;
        gap: var(--bfs12);
      }

    }

  }


}


