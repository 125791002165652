.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-spinner {
  font-size: 30px;
  animation: rotate-spinner 1.5s linear infinite;
  display: inline-block;
  color: var(--bfc-base-3);
}

@keyframes rotate-spinner {
  100% { transform: rotate(360deg) }
}
