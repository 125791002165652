.iams-user-row-first-cell {
  display: flex;

  > span {
    margin: auto 0;
  }

  > svg {
    margin: auto 0 auto var(--bfs4);
  }

  div:first-child {
    display: flex;

    input {
    }

    label {
      margin: auto 0;
      padding-right: var(--bfs4);
      overflow-x: hidden;
    }
  }
}
.iams-row {


  > div:first-child {
    padding-top: var(--bfs24);
  }

  > div:last-child {
    border-top: 1px solid var(--bfc-base-c-dimmed);
    padding-bottom: var(--bfs16);
  }

  .iams-grid {
    padding: 0 var(--bfs24);
    --bfl-columns: 2;


    > div {
      margin-bottom: var(--bfs16);

      > p {
        margin: 0;
        line-break: anywhere;
      }

      > div {
        > p {
          margin: 0
        }
      }

    }

  }
  @media (min-width: 600px) {
    .iams-grid {
      --bfl-columns: 3;
    }
  }
  @media (min-width: 800px) {
    .iams-grid {
      --bfl-columns: 5;
    }
  }

  @media (min-width: 1280px) {
    .iams-grid {
      --bfl-columns: 7;
    }
  }
}

.os-edit-row {

  > div {
    max-width: 140px;
  }
}

.date-edit-row {
  display: flex;
  flex-direction: row;

  input {
    max-width: 140px;
  }

  .bf-button{
    max-width: 60px;
    min-width: 60px;
    padding: 0;
  }
}

.button-edit-row-warn {
  border: 1px solid var(--bfc-warning);
  color: var(--bfc-warning);
}
.button-edit-row-good {
  border: 1px solid var(--bfc-success);
  color: var(--bfc-success);
}
.button-edit-row-bad {
  border: 1px solid var(--bfc-alert);
  color: var(--bfc-alert);
}
.button-edit-row-neutral {
  border: 1px solid var(--bfc-base-c-1);
  color: var(--bfc-base-c-1);
}
