#backoffice {
  position: relative;
  height: 100%;

  .backoffice-top-bar {
    background: var(--bfc-base-2);
    padding: var(--bfl-page-padding);

    .top-bar-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > button {
        margin: auto 0;
      }

    }

    .top-bar-content {
      --bfl-columns: 1;
    }
    @media (min-width: 1279px) {
      .top-bar-content {
        --bfl-columns: 2;
      }
    }

    .left-container {

      .header {
        display: flex;
        flex-direction: row;
        margin-bottom: var(--bfs32);

        > h2 {
          margin-top: var(--bfs24);
          margin-bottom: 0;
        }

        > button {
          align-self: flex-end;
          margin: 0 20px;
        }

      }

    }


    .right-container {
      display: flex;
      flex-direction: column;

      > button {
        align-self: flex-end;
        margin-bottom: var(--bfs24);
      }
    }

    .container-box {
      display: flex;
      flex-direction: row;
      align-items: center;

      > * {
        margin: 0;
      }

    }

  }

  .label-box {
    padding: var(--bfs24);
    background: var(--bfc-base-3);
    min-height: 96px;
    display: flex;
    flex-direction: row;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: auto;
      margin-top: auto;
      font-weight: 600;
      color: var(--bfc-base-c-2);

      > * {
        margin: 0;
        padding: 0;
      }

      > h3 {
        color: var(--bfc-base-c-1);
      }

    }

    > div {
      display: flex;
    }

    > div:first-child {
      border-right: 1px solid var(--bfc-base-c-dimmed);
      padding-right: var(--bfs24);
    }
    > div:last-child {
      padding-left: var(--bfs24);
    }

  }

  .content {
    display: flex;
    width: 100%;

    .content-full {
      width: 100%;
      margin: var(--bfl-page-padding);
    }

    .content-main {
      padding: var(--bfl-page-padding);
      width: 100%;
    }

  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        width: 80%;
      }
    }
  }

  .backoffice-content {
    display: flex;
    width: 100%;

    > div {
      padding: var(--bfl-page-padding);
      width: 100%;
    }

  }
  @media (min-width: 1921px) {
    .backoffice-content {
      justify-content: space-around;
      > div {
        width: 80%;
      }
    }
  }

  .container-dropdown-content {

    > p {
      cursor: pointer;
    }
    > p:hover {
      color: var(--bfc-base-c-wcag);
      text-decoration-line: underline;
      -moz-text-decoration-line: underline;
    }

    > div {

      > label {
        padding-left: 0;
      }

    }

  }


}