#contact-information {
  .contact-container-text {
    text-align: center;
    word-break: break-word;

    h5 {
      margin: 10px 0 0 0;
    }

    p {
      margin: 0;
    }
  }


}