.applications-overview-table-wrapper {
  border-radius: var(--bf-radius-s);
  border: 1px solid var(--bfc-base-dimmed);
  background: var(--bfc-base-3);
}

.applications-overview-table-cell-with-border.applications-overview-table-cell-with-border {
  border-right: 1px solid var(--bfc-base-dimmed);
  padding-left: 24px;
  min-width: 300px;
}

.applications-overview-table-cell {
  padding: 0;
}

.application-table-filter {
  padding: var(--bfs12);

  .table-search-and-filter-bar {
    display: flex;

    > * {
      margin-left: var(--bfs12);
    }
    > div:last-of-type {
      margin-left: auto;
    }
    > *:first-of-type {
      margin-left: 0;
    }

    * {
      z-index: 5;
    }

    .app-bar-filter-buttons {
      display: flex;
      gap: var(--bfs8);

      > div:last-of-type {
        flex: 1;
        justify-content: space-between;
      }

    }

    @media (max-width: 600px) {
      .app-bar-filter-buttons {
        flex-direction: column;
      }
    }

  }
  @media (max-width: 1279px) {
    .table-search-and-filter-bar {
      display: grid;
      --bfl-columns: 1;
      --bfl-gap: var(--bfs8);

      > div {
        margin-left: 0;
      }

    }
  }

  .application-table-filter-tags {
    display: flex;
    flex-direction: row;
    gap: var(--bfs8);

    > * {
      margin-top: var(--bfs12);
    }

  }
    border-bottom: 1px solid var(--bfc-base-dimmed);

}


.bulk-edit-bar {
  background: var(--bfc-base-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--bfc-base-dimmed);
  .bulk-edit-buttons {
    display: flex;
    padding: var(--bfs12);
    border-left: 1px solid var(--bfc-base-dimmed);
    gap: 8px;
  }
  .environment-select {
    margin: var(--bfs12);
    gap: 12px;
    * {
      z-index: 4;
    }
    > button {
      width: 200px;
    }
  }
  button {
  }

}

.applications-overview-pagination-line {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 58px;
  border-top: 1px solid var(--bfc-base-dimmed);
  background: var(--bfc-base-2);
  border-bottom-left-radius: var(--bf-radius-s);
  border-bottom-right-radius: var(--bf-radius-s);
}

.applications-overview-pagination-row-select {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.applications-overview-pagination-row-select div {
  margin-right: 8px;
}

.select-and-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;

  > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 8px;
  }

}

.search-field-select.search-field-select {
  width: 130px;
}

.filter-and-column-select {
  width: 200px;
}
