.iams-content {

  > table {

    > tbody {

      > tr.iams-table-row {

        > td {

          padding-top: 10px;
          padding-bottom: 10px;

        }

      }

    }

  }

  .bottom-line {
    display: flex;
    justify-content: space-between;

    .row-box {
      width: 75px;
      margin-left: var(--bfs8);
      margin-top: var(--bfs8);
    }

  }
}