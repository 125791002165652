#global-team-settings {

  .backoffice-editable-container-drawer {
    display: flex;
    flex-direction: column;

    .checkbox-group {
      margin-top: var(--bfs24);
      --bfl-columns: 1;

      p {
        color: var(--bfc-base-c-2);
      }

    }
    @media (min-width: 600px) {
      .checkbox-group {
        --bfl-columns: 2;
      }
    }

    .input-group {
      margin-top: var(--bfs24);

      > p {
        margin: 0;
        color: var(--bfc-base-c-2);
      }

      > div {
        margin: var(--bfs8) 0;
      }

      .input-group-footer {
        display: flex;

      }

      .input-group-role-row {
        display: flex;
        flex-direction: row;

        > fieldset {
          flex: 1;
        }

        > button {
          border: 0;
          background: var(--bfc-base-2);
          > svg {
            color: var(--bfc-base-c-2);
          }
        }

        > button:hover {
          > svg {
            color: var(--bfc-base-c);
          }
        }

        > svg {
          cursor: pointer;
          margin: auto var(--bfs16);
        }
        > svg:hover {
          transform: scale(1.5);
        }


      }



    }

    .team-drawer-footer{
      margin-top: auto;
      display: flex;
      flex-direction: row;

      > button:last-of-type {
        margin-left: var(--bfs12);
      }

      > button:first-of-type {
        margin-right: auto;
      }

    }

  }


}