.contact-box {
  min-height: 271px;
  max-width: 310px;
  display: inline-block;

  .contact-icon-image {
    position: absolute;
    top: 35%;
    border-radius: 50%;
    right: 37%;
    height: var(--bfs24);
    width: var(--bfs24);
  }


}