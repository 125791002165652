#login-main {
  height: 100%;
}

#login {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

#login-box {
  border-radius: 11px;
  background: var(--bfc-base-3);
  box-shadow: 0px 4px 8px 0px var(--bfc-shadow);
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 312px;
  padding: var(--bfs24) var(--bfs40);

  .login-header {
    display: flex;
    flex: 1 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login-image {
      height: 80px;
      width: 80px;
      justify-content: center;
      align-items: center;

      .image {
        width: 100%;
        border-radius: 10px 10px 0 0;

      }
    }

    > h3 {
      margin: var(--bfs12) 0 var(--bfs12) 0;
    }

  }


  .login-content {
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex: 1 2;

    > button:last-of-type {
      margin-top: var(--bfs12);
    }

    .intility-footer {
      display: flex;
      width: 184px;
      margin: auto auto 0;
    }

  }
}
