
#locations {

  .content-main {


    .locations-box {
      display: flex;
      flex-direction: column;

      > p {
        margin-top: var(--bfs4);
      }

      .content-main-search {
        display: flex;
        justify-content: space-between;

        > div {
          display: flex;

          > div:first-of-type {
            width: 265px;
            margin-right: var(--bfs12);
          }

        }
        @media (max-width: 600px) {
          > div:first-of-type {
            display: flex;
            flex-direction: column;

            > div {
              flex: 1;
              width: 100%;

              .bf-button-group {
                flex: 1;
              }

            }

            > div:first-of-type {
              margin-bottom: var(--bfs8);
            }

          }
        }

      }
      @media (max-width: 940px) {
        .content-main-search {
          flex-direction: column;

          > * {
            margin-bottom: var(--bfs8);
          }
          > *:last-of-type {
            margin-bottom: 0;
          }

        }
      }

      .location-cards {
        margin-top: var(--bfs16);
        --bfl-gap: var(--bfs16);
      }

    }


  }

}