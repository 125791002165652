#company-overview-new {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    width: 100%;

    .content-main {
      padding: var(--bfl-page-padding);
      width: 100%;

      .date-picker-row {

        * {
          z-index: 100;
        }

        button {
          z-index: 101;
        }

        .react-datepicker {
          z-index: 3;
        }

        > div:first-of-type {
          margin-right: var(--bfs8);
        }

      }

      .card-bar {
        display: flex;
        flex-direction: row;
        margin-bottom: var(--bfs24);

        .overview-cards {
          width: 100%;

        }

        .favorite-cards {
          display: flex;
          flex-direction: row;
          overflow: auto;
        }

      }
      @media (max-width: 1330px) {
        .card-bar {
          flex-direction: column;

        }
      }

      .search-bar {
        margin-bottom: var(--bfs12);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .button-group-section {
          display: flex;
          flex-direction: row;
          margin: auto 0;

          > button {
            margin-right: var(--bfs12);
          }

          > div {
            margin-right: var(--bfs12);
          }

          > div:last-of-type {
            margin-right: 0;
          }

          @media (max-width: 1040px) {
            > div:first-of-type {
              margin-right: 0;
              margin-bottom: var(--bfs8);
            }
          }
        }

        .date-button-section {
          display: flex;
          flex-direction: row;
          margin: auto 0;
        }

        @media (max-width: 1040px) {
          .button-group-section {
            flex-direction: column;
          }
        }

        @media (max-width: 1040px) {
          .date-button-section {
            margin: auto 0 0 0;
          }
        }


      }
      @media (max-width: 1313px) {
        .search-bar {
          flex-direction: column;

          .date-button-section {
            margin-top: var(--bfs8);
          }

        }
      }


      .overview-table {
        background: var(--bfc-base-3);
        border-top-left-radius: 3px;
        overflow-y: scroll !important;

        .overview-table-tabs {
          z-index: 12;
          position: sticky;
          display: flex;
          left: 0;
          top: 0;
        }

        .table-header {
          height: 58px;
          display: flex;
          flex-direction: row;
          position: -webkit-sticky;
          position: sticky;
          top: 49px;
          z-index: 11;


          .table-header-customer {
            min-width: 390px;
            height: 58px;
            position: -webkit-sticky;
            position: sticky;
            z-index: 10;
            background: var(--bfc-base-3);
            left: 0;
            border-left: 6px solid var(--bfc-base-c-theme);
            border-right: 1px solid var(--bfc-base-c-dimmed);
            border-bottom: 1px solid var(--bfc-base-c-dimmed);
            border-top-left-radius: 3px;
            display: flex;
            flex-direction: row;
            padding: var(--bfs16) var(--bfs24);

            > p {
              color: var(--bfc-base-c-theme);
            }

            > * {
              align-self: center;
            }
          }

          .table-header-days {
            display: flex;
            flex-direction: row;
            flex: 1;
            border-right: 1px solid var(--bfc-base-c-dimmed);
            border-bottom: 1px solid var(--bfc-base-c-dimmed);
            background-color: var(--bfc-base-3);

            .table-header-month {
              margin-left: 1px;
              border-right: 1px solid var(--bfc-base-c-dimmed);
              background-color: var(--bfc-base-3);

              > p {
                margin: 0;
              }

              > div {
                display: flex;
                flex-direction: row;

                > span {
                  background-color: var(--bfc-base-dimmed);
                  display: flex;
                  justify-content: center;
                  position: relative;
                  cursor: pointer;
                  margin: 0 var(--bfs2) 0 var(--bfs2);

                  .marker-active {
                    width: 26px;
                    position: absolute;
                    opacity: 0.2;
                    top: 0;
                    background: var(--bfc-success);
                  }

                  .marker {
                    width: 100%;
                    position: absolute;
                    top: 0;
                  }

                }

                .outline {
                  border: 1px solid var(--bfc-base-c);
                }

                > span:first-of-type {
                  margin: 0 2px 0 1px;
                }

                > span:last-of-type {
                  margin: 0 1px 0 2px;
                }

              }
            }

            .table-header-month:last-of-type {
              border: 0;
            }


          }

        }

        .table-body {

          .table-row:first-of-type {

            .table-row-content {
              box-shadow: inset 0px 4px 4px -3px rgba(0, 0, 0, 0.15);
            }

            .overview-header:first-of-type {
              box-shadow: inset 0px 4px 4px -3px rgba(0, 0, 0, 0.15);
            }

          }

          .table-row {
            max-height: 116px;
            display: flex;
            flex-direction: column;

            .table-row-content {
              width: 100%;
              height: 58px;
              display: flex;
              flex-direction: row;
              border-right: 1px solid var(--bfc-base-c-dimmed);

              .overview-header {
                border-right: 1px solid var(--bfc-base-c-dimmed);
                border-bottom: 1px solid var(--bfc-base-c-dimmed);
                border-left: 6px solid var(--bfc-base-dimmed);
                background: var(--bfc-base-3);
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                display: flex;
                flex-direction: row;
                min-width: 390px;
                max-width: 390px;
                height: 58px;
                z-index: 10;


                > button {
                  cursor: pointer;
                  margin: var(--bfs2);
                  margin-left: var(--bfs8);
                  font-size: var(--bf-font-size-m);
                  white-space: nowrap;
                  background: transparent;
                  border: 0;
                  color: var(--bfc-base-c-theme);
                  align-items: center;
                  padding: 5px;
                  display: flex;

                  > svg {
                    height: 16px;
                    width: 16px;
                  }

                }

                > button:hover {
                  background-color: var(--bfc-base-dimmed);
                  border-radius: 50%;
                }

                > button:focus {
                  outline: none;
                }

                > button:focus-visible {
                  background-color: var(--bfc-base-dimmed);
                  border-radius: 50%;
                  position: relative;
                }

                > * {
                  align-self: center;
                }

                .overview-header-fav {
                  margin: auto var(--bfs4);
                }

                .overview-header-title {
                  flex: 1;
                  margin: auto var(--bfs4);
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;

                  > div {
                    display: flex;
                    margin-right: var(--bfs4);

                    > div {
                      margin: auto;
                    }

                  }

                  .backoffice-cog {

                    > a:hover {

                      > svg {
                        transition: all 0.3s ease 0s;
                        transform: rotate(360deg);
                      }

                    }

                  }

                }

              }

              .company-progress {
                flex: 1;
                padding: var(--bfs8) 0;
                cursor: pointer;

                .children {
                  display: flex;
                  flex-direction: row;
                  height: 30px;
                }

                > div {
                  display: flex;
                  flex-direction: column;
                  // background-color: var(--bfc-theme-fade);

                  > div:first-of-type {
                    height: var(--bfs24);
                  }

                  .text-content {
                    display: flex;
                    flex-direction: row;
                    margin-top: 1px;
                    position: absolute;
                    min-width: 800px;
                    bottom: -20px;
                    right: 0;

                    > * {
                      margin-right: var(--bfs12);
                    }

                    > p  {
                      margin-top: 0;
                      margin-bottom: 0;
                    }

                    .overview-row-image {
                      display: flex;
                      height: 16px;
                      border-radius: 3%;

                      > p  {
                        margin-top: 0;
                        margin-bottom: 0;
                      }

                      > img {
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: var(--bfs12);
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                      }

                      > svg {
                        margin-left: auto;
                        margin-right: auto;
                        color: var(--bfc-base-c-2);
                        height: 16px;
                        width: 16px;
                      }

                    }

                  }

                }

              }

              .company-progress:hover {
                background-color: var(--bfc-base);
              }

              .company-progress.active-company {
                background-color: var(--bfc-success-fade);
              }

            }

            .overview-dropdown {
              display: flex;
              flex-direction: row;
              height: 58px;
              border-right: 1px solid var(--bfc-base-c-dimmed);

              .dropdown-percentage {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                align-self: flex-end;
                padding: var(--bfs8) var(--bfs12);
                background-color: var(--bfc-base-2);
                border-right: 1px solid var(--bfc-base-c-dimmed);
                border-bottom: 1px solid var(--bfc-base-c-dimmed);
                height: 58px;
                border-left: 6px solid var(--bfc-base-dimmed);
                width: 390px;

                > span {
                  color: var(--bfc-base-c-theme);
                  display: flex;
                  flex-direction: row;

                  > a {
                    margin: 0 var(--bfs4) 0 0;
                  }

                  > svg {
                    align-self: center;
                  }

                }

              }

              .dropdown-empty {
                flex: 1;
                border-right: 1px solid var(--bfc-base-c-dimmed);
                border-bottom: 1px solid var(--bfc-base-c-dimmed);
                align-self: flex-end;
                // height: 58px;
                width: 390px;
              }


              > * {
                align-self: center;
              }

            }

            .overview-dropdown.active-company {
              background-color: var(--bfc-success-fade);
            }


          }

        }




      }

      .favorite-container {
        padding: var(--bfl-box-padding) 0;
        --bfl-autocol-width: 250px;
        gap: 24px;

      }
    }
  }
}