
.review-modal {
  min-height: 851px;
  min-width: 1000px;

  .review-modal-select {
    display: flex;

    > div:first-child {
      flex: 1;
    }

    > div:last-child {
      flex: 2;
    }

  }

  .usermatch-table {

    th {
      text-align: left;
    }

  }

}

.modal-button-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: var(--bfs40);

  > div {

    > button:first-child {
      margin-right: var(--bfs8);
    }

  }

}
