.contact-container {
  margin-top: var(--bfs40);

  .contact-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .contact-grid {
    --bfl-columns: 2;
  }
  @media (min-width: 800px) {
    .contact-grid {
      --bfl-columns: 3;
    }
  }
  @media (min-width: 896px) {
    .contact-grid {
      --bfl-columns: 4;
    }
  }
  @media (min-width: 1279px) {
    .contact-grid {
      --bfl-columns: 5;
    }
  }

}

