$imageWidth: 250px;
$imageHeight: calc($imageWidth / 1.7777); // To roughly maintain a 16:9 aspect ratio.
$borderWidth: 3px;
$iconPadding: 0.5em;

.wallpaper-option {
  position: relative;
  margin: 0 var(--bfs12) var(--bfs12) 0;
  display: flex;
  flex-direction: column;
  max-width: $imageWidth;
  align-items: center;

  .wallpaper-option-badge {
    position: absolute;
    margin: var(--bfs8);
    border-radius: 3px;
    top: 0;
    right: 0;
    background: var(--bfc-base-c-1);
    cursor: pointer;

    a {
      text-decoration: none;
    }

    p {
      color: var(--bfc-base-3);
      margin: 0 var(--bfs4);
    }

  }

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
    }

    > svg {
      margin: var(--bfs4);
      transform: rotate(0deg);
      font-size: 26px;
      color: var(--bfc-base-c-wcag)
    }

    .error-message-container {
      position: relative;

      .wallpaper-option-warning {
        color: var(--bfc-warning);
      }

      .error-message {
        position: absolute;
        background-color: var(--bfc-base-3);
        top: 0;
        right: 30px;
        z-index: 1;
        width: 300px;
        opacity: 0;
        visibility: hidden;
        border-radius: 3px;
        padding: var(--bfs12);
      }

      .wallpaper-option-warning:hover + .error-message {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  .wallpaper-option-image {
    width: 100%;
    max-width: $imageWidth;
    min-width: $imageWidth;
    max-height: $imageHeight;
    min-height: $imageHeight;
    object-fit: contain;
    -webkit-user-drag: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active-wallpaper {
      padding: 0;
      outline: $borderWidth solid var(--bfc-base-c-theme);
    }

    &.no-access {
      cursor: not-allowed;
    }
  }

  .error-message {
    font-size: 0.8rem;

    p:nth-child(2) {
      margin-top: var(--bfs12);
    }
  }

  .wallpaper-option-title {
    margin-top: var(--bfs12);
  }
}
