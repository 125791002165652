.printer-card {
  display: flex;
  flex-direction: row;
  background-color: var(--bfc-base-2);
  padding: var(--bfs12) 10px;
  //align-items: center;

  > p {
    margin: 0;
  }

  > button {
    align-self: flex-start;
  }

  .printer-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;


    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--bfc-base-dimmed);
      border-radius: 50%;
      min-width: 35px;
      width: 35px;
      height: 35px;
      margin-right: var(--bfs24);

      > svg {
        color: var(--bfc-base-c-inverted-2);
      }

    }

  }

  .printer-card-body {
    display: flex;
    flex-direction: column;
    flex: 1;

    > p {
      margin: 0;
      margin-right: auto;
      align-self: center;
    }

    .printer-body-elements {
      display: flex;
      flex-direction: row;

      > span {
        display: inline;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: var(--bfs16);
        color: var(--bfc-base-c-2);

        > svg {
          margin-right: var(--bfs4);
        }
      }

    }
    @media (max-width: 960px) {
      .printer-body-elements {
        flex-wrap: wrap;
      }
    }

  }
  @media (max-width: 1280px) {
    .printer-card-body {
      flex-direction: column;
    }
  }

}