.empty-users-state {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 8px 0px var(--bfc-shadow);
  border-radius: var(--Border-radius-L, 16px);
  max-width: 1170px;
  align-self: center;


  > div {
    flex: 1 2;
  }

  .empty-users-state-image-container {
    background: var(--bfc-base-2);
    display: flex;
    justify-content: center;
    border-radius: var(--Border-radius-L, 16px) 0 0 var(--Border-radius-L, 16px);

    > div {
      padding: 80px;
      display: flex;
      justify-content: center;

       > img {
         margin: auto;
       }
    }
    @media (max-width: 1280px) {
      > div {
        padding: 40px;
      }
    }

  }

  .empty-users-state-text-container {
    padding: 80px;
    background: var(--bfc-base-3);
    border-radius: 0 var(--Border-radius-L, 16px) var(--Border-radius-L, 16px) 0;
  }

  @media (max-width: 1280px) {
    .empty-users-state-text-container {
      padding: 40px;
    }
  }

}

@media (max-width: 960px) {
  .empty-users-state {
    flex-direction: column;

    .empty-users-state-image-container {
      border-radius: var(--Border-radius-L, 16px) var(--Border-radius-L, 16px) 0 0;
    }

    .empty-users-state-text-container {
      border-radius: 0 0 var(--Border-radius-L, 16px) var(--Border-radius-L, 16px);
    }

  }
}