#printer-drawer {

  .printer-drawer-input {
    --bfl-columns: 2;

  }

  .printer-drawer-checkboxes {
    margin-top: var(--bfs12);

    > div {
      margin-top: var(--bfs4);
      --bfl-columns: 2;

      > div {
        display: flex;

        > label {
          flex: 1;
        }

      }

    }

  }

  .application-box {
    background-color: var(--bfc-base-dimmed);
    padding: var(--bfs8);
    margin-top: var(--bfs12);
    margin-bottom: var(--bfs12);

    > button {
      margin-top: var(--bfs8);
      margin-bottom: var(--bfs12);
    }

  }

}