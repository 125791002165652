// used for spacing elements next to each other horizontally
// the first element gets no margin left, and last gets no margin right
@mixin space-beside($spacing) {
  margin: 0 $spacing;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

// used for spacing elements underneath each other
// first element gets no margin top, all other have margin top
@mixin space-vertically($spacing) {
  margin-top: $spacing;
  margin-bottom: 0;

  &:first-child {
    margin-top: 0;
  }
}