.team-edit-modal-dropdown {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: var(--bfs8);

  .member-photo-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    margin-right: var(--bfs8);

    > img {
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }

  }

  > * {
    margin: auto 0;
  }

}

.team-edit-modal-dropdown:last-of-type {
  margin-bottom: 0;
}

.team-edit-modal-dropdown:hover {
  background: var(--bfc-base-1);
}

.team-edit-modal-buttons {
  margin-top: var(--bfs24);
  display: flex;
  justify-content: space-between;

  > button:first-of-type {
    margin-right: var(--bfs8);
  }

}
