.articleComponent {
  display: flex;
  background: var(--bfc-base-3);
  border-radius: 3px;
  overflow: hidden;

  img {
    object-fit: fill;
    //max-height: 200px;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .textContainer {
      padding: var(--bfs16);
      flex: 1;

      > h4 {
        margin: 0;
        color: var(--bfc-base-c-theme);
      }

      > p {
        margin-top: var(--bfs4);
      }
    }

    .read-article-link-container {
      display: flex;
      border-top: 1px solid var(--bfc-base-c-dimmed);
      padding: var(--bfs12);

      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 600px) {
    .articleImage {
      display: none;
    }
  }
}

.task-article-component {
  display: flex;
  flex-direction: column;
  background: var(--bfc-base-3);
  border-radius: 10px;
  overflow: hidden;
  max-width: 290px;

  .task-article-container {
    height: 100%;
    background-color: var(--bfc-base-2);

    .article-image {
      display: flex;
      margin: auto;
      height: 163px;
      padding: 0;
    }
  }

  .read-article-link-container {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--bfc-base-c-dimmed);
    padding: var(--bfs12);

    justify-content: center;
    align-items: center;
  }

}

@media (max-width: 600px) {
  .task-article-component {
    max-width: 600px;
  }
}

.article-skeleton {
  max-height: 150px;
}
