.add-application-page {
  max-width: 764px;
  margin: 0 auto;
}

.add-application-page-content {
  display: grid;
  gap: 24px;
}

.basic-detail-box {
  border-radius: 12px;
  background: var(--bfc-base-3);
  box-shadow: 0px 4px 8px 0px var(--bfc-shadow);
}

.basic-detail-box-header {
  border-bottom: 1px solid var(--bfc-base-dimmed);
  padding: 16px 16px;
}

.basic-detail-box-content {
  padding: 24px 16px;
}

.optional-fields-box-content {
  padding: 24px 16px;
  gap: 12px;
}

.contacts-box-content {
  padding: 24px 16px;
  gap: 24px;
}

.add-application-page h1 {
  margin: 0px;
  margin-top: 12px;
}

.add-application-page h5 {
  margin: 0px;
}

.added-contact {
  border-radius: var(--bf-radius-s);
  border: 1px solid var(--bfc-base-dimmed);
  background: hsla(var(--bfc-theme-hsl), 0.05);
  padding: 24px;

  .contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.contact-role-badge {
  margin-right: 4px;
}

.add-new-contact-card {
  border-radius: 8px;
  border: 1px solid var(--bfc-base-dimmed);
  background: var(--bfc-base-2);
  padding: 24px;
}

.edit-contact-card {
  border-radius: 8px;
  border: 1px solid var(--bfc-base-dimmed);
  background: hsla(var(--bfc-theme-hsl), 0.05);
  padding: 24px;
}

.add-application-contact-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  align-items: flex-start;
  > .add-contact-button {
    margin-top: 0px;
    white-space: nowrap;
    width: 182px;
    min-width: 182px;
  }
}

.add-new-internal-contact-details {
  background: var(--bfc-base-1);
  border: 1px solid var(--bfc-base-dimmed);
  border-radius: var(--bf-radius-s);
  padding: var(--bfs12);
}

.add-new-internal-contact-details-placeholder {
  color: var(--bfc-base-c-2);
  text-align: center;
  font-size: var(--bf-font-size-l);
  font-style: italic;
  font-weight: 400;
}

.new-internal-contact-info-label {
  color: var(--bfc-base-c-1); 
  font-size: var(--bf-font-size-m);
  font-weight: 600; 
}

.new-internal-contact-info-value {
  color: var(--bfc-base-c-1); 
  font-size: var(--bf-font-size-l); 
  font-weight: 400;  
}

.delete-contact-undo-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .add-application-contact-container {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    align-items: flex-start;
  }
  .add-application-contact-container .add-contact-button {
    margin-top: 16px;
  }
}
