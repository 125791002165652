.files-container {
  margin: 12px 0;
}

.file {
  padding: 6px;
  display: inline-block;
  color: #5C656D;
}

.file > i {
  margin-left: 6px;
  color: #BF0D3E;
  cursor: pointer;
}

.simple-button-uploader {

  > button {
    cursor: pointer;
    font-size: var(--bf-font-size-m);
    white-space: nowrap;
    background: transparent;
    border: 0;
    color: var(--bfc-base-c-theme);
    align-items: center;
    margin: var(--bfs2);
    padding: 10px;

    > svg {
      width: 21px;
    }

  }

  > button:hover {
    padding: 9px;
    border-radius: 50%;
    border: 1px solid var(--bfc-base-dimmed);
  }

  > button:focus {
    outline: none;
  }

  > button:focus-visible {
    outline: 1px dashed var(--bff-theme);
    -moz-outline-radius: var(--bfl-border-radius);
    /* make sure the outline is visible on top of expanded accordion item content */
    position: relative;
    z-index: 1;
  }



}

.simple-uploader {
  margin-bottom: var(--bfs8);
}

.application-file-uploader {
  background-color: var(--bfc-base-3);

  > div {
    display: flex !important;
    height: 100%;

    .wallpaper-option-image {
      margin: auto;
      display: flex;

      .icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > svg {
          color: var(--bfc-base-c-2);
          height: var(--bfs40);
          width: var(--bfs40);
        }

        > span {
          display: flex;
          flex-direction: row;

          > p {
            padding-right: var(--bfs4);
          }

          > p:last-of-type {
            padding-right: 0;
          }

        }

      }
    }

  }


}