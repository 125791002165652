#new-contact {
  display: flex;
  flex-direction: column;

  > button {
    margin-right: var(--bfs24);
    position: absolute;
    right: 0;
  }

  .body {
    flex: 1;

    .vendor-content {
      margin-top: var(--bfs24);
      --bfl-columns: 1;
    }
    @media (min-width: 600px) {
      .vendor-content {
        --bfl-columns: 2;
      }
    }

    .internal-content {
      flex: 1;
      margin-top: var(--bfs24);
      --bfl-columns: 1;
    }
    @media (min-width: 600px) {
      .internal-content {
        --bfl-columns: 2;
      }
    }
  }

}

.integration-option-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--bfc-base-c-2);

  > p {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: var(--bfs16);

  }

}

#new-integration {
  display: flex;
  flex-direction: column;

  > button {
    margin-right: var(--bfs24);
    position: absolute;
    right: 0;
  }

  .body {
    flex: 1;

    .integration-content {
      margin-top: var(--bfs24);
      --bfl-columns: 1;
    }

    @media (min-width: 600px) {
      .integration-content {
        --bfl-columns: 2;
      }
    }
  }

  .button-row {

    > button {
      margin-left: var(--bfs12);
    }
    > button:first-child {
      margin-left: 0;
    }

    justify-content: flex-end;
    display: flex;
    width: 100%;
  }

}
