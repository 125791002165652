
.new-location-modal {
  min-width: 500px;

  .select-row {
    display: flex;
    flex-direction: row;

    > div {
      flex: 1;
    }
    > div:first-of-type {
      margin-right: var(--bfs8);
    }

  }

  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--bfs40);

  }


}