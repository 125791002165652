.service-article {
  padding: var(--bfs24);
  border-radius: 11px;
  background: var(--bfc-base-3);
  display: flex;
  flex-direction: column;

  .service-article-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    .left-header {
      display: flex;
      flex-direction: row;
      align-content: center;

      .article-image {
        width: 42px;
        height: 42px;
        display: flex;
        margin-right: var(--bfs12);
      }

      > div {

        > h5 {
          color: var(--bfc-theme-neutral);
          margin: 0;
        }

        > p {
          color: var(--bfc-base-c-2);
          margin: 0;
        }

      }
    }



  }


  .content-container {

    > div {

      > p {
        margin-top: 0;
      }

    }


  }

}

.service-article:hover {
  cursor: pointer;

  .service-article-header {

    .left-header {

      > div {

        > h5 {
          text-decoration-line: underline;
          -moz-text-decoration-line: underline;
        }

      }

    }

  }

}