#contact {
  display: flex;
  flex-direction: column;

  .location-contacts-title {
    text-align: center;
    margin: var(--bfs12) 0;
  }

  .content {
    display: flex;
    flex-direction: row;

    .content-main {
      width: 100%;

      .main-contact-grid {
        --bfl-columns: 1;
      }
      @media (min-width: 450px) {
        .main-contact-grid {
          --bfl-columns: 2;
        }
      }
      @media (min-width: 800px) {
        .main-contact-grid {
          --bfl-columns: 3;
        }
      }
      @media (min-width: 896px) {
        .main-contact-grid {
          --bfl-columns: 4;
        }
      }
      @media (min-width: 1279px) {
        .main-contact-grid {
          --bfl-columns: 5;
        }
      }


      h2 {
        margin: 0 0 0 12px;
      }
    }

  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        width: 80%;
      }
    }
  }


  .location-contacts {
    display: flex;
    flex-wrap: wrap;
  }
}