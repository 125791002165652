#applications {
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  .content {
    flex-direction: column;

    .content-main {
      width: 100%;
    }


    .applications-top-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid var(--bfc-base-dimmed);
    }
  }

  .applications-page-title {
    display: flex;
    flex-direction: column;
    gap: var(--bfs8);
    align-self: stretch;

    > *:first-of-type {
      margin-bottom: var(--bfs8);
    }

    > p {
      margin: 0;
    }

    > h2 {
      margin: 0;
    }

  }

  .header-next-step {
    display: flex;
    max-width: 610px;
    padding: var(--bfs24);
    flex-direction: column;
    gap: var(--bfs8);
    border-radius: var(--bf-radius-l);
    border: 1px solid var(--bfc-base-dimmed);
    background: var(--bfc-base-3);

    > p {
      margin: 0;
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;

      > h5 {
        margin: 0
      }

    }
  }

}

.applications-empty-stage-card {
  display: flex;
  flex-direction: row;
  max-width: 1170px;
  border-radius: var(--Border-radius-L, 16px);
  box-shadow: 0px 4px 8px 0px var(--bfc-shadow);
  margin: 40px auto;

  .applications-empty-state-image-box {
    display: flex;
    flex-direction: row;
    padding: var(--bfs40);
    justify-content: center;
    align-self: stretch;
    background: var(--bfc-base-2);
    border-radius: 16px 0 0 16px;
    gap: var(--bfs40);
    border-right: 1px solid var(--bfc-base-dimmed);

    > div {

      .header {
        display: flex;
        flex-direction: row;

        > div {
          margin-right: auto;
          > h1 {
            margin: 0;
          }

          > h5 {
            margin: 0 0 var(--bfs8);
          }
        }

        > img {
          height: 112px;
          width: 112px;
        }

      }


      > p {
        margin: var(--bfs24) 0;
      }

    }

    .application-empty-state-image {
      width: 307px;
      height: 100%;
    }

  }

  @media (max-width: 959px) {
    .applications-empty-state-image-box {
      border-bottom: 1px solid var(--bfc-base-dimmed);
      border-right: 0;
      border-radius: 16px 16px 0 0;
    }
  }

  .applications-empty-state-text-box {
    display: flex;
    padding: var(--bfs40);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--bfs16);
    flex: 1 0 0;
    align-self: stretch;
    background: var(--bfc-base-3);
    border-radius: 0 16px 16px 0;
    min-width: 405px;

    > h5 {
      margin: 0;
    }

    > p {
      margin: 0;
    }

  }

  @media (max-width: 959px) {
    .applications-empty-state-text-box {
      border-radius: 0 0 16px 16px;
    }
  }

}



.applications-empty-state-text-box-header {
  color: var(--bfc-base-c-1);
  font-size: var(--bf-font-size-h1);
  font-weight: var(--font-weight-XL, 660);
}

.applications-empty-state-text-box-text {
  color: var(--bfc-base-c-1);
  font-size: var(--bf-font-size-l); 
  font-weight: 400;
  line-height: 150%;
}
