.office365-table {

  > p {
    padding-left: var(--bfs16);
    padding-right: var(--bfs16);
    margin-top: 0;
  }

  table {

    .bf-table-header {

      .bf-table-header-cell {
        background-color: var(--bfc-base-2);
        border-bottom: 1px solid var(--bfc-base-c-dimmed);
        padding-top: var(--bfs8);
        padding-bottom: var(--bfs8);
      }
    }

    border-top: 1px solid var(--bfc-base-c-dimmed);

    .bf-table-body {

      .bf-table-row {

        .bf-table-cell {
          background-color: var(--bfc-base-2);
          padding-top: var(--bfs8);
          padding-bottom: var(--bfs8);
        }

      }

    }

  }

}