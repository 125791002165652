#backoffice-project-team {
  --bfl-columns: 1;
  --bfl-gap: var(--bfs20);
}
@media (min-width: 960px) {
  #backoffice-project-team {
    --bfl-columns: 2;
  }
}
@media (min-width: 1600px) {
  #backoffice-project-team {
    --bfl-columns: 4;
  }
}