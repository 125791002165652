#backoffice-application-details {

  .progress-bar {
    margin-bottom: var(--bfs32);
  }

  .button-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .search-section {
      display: flex;
      flex-direction: row;

      > *:last-child {
        min-width: 75px;
      }

    }

    .button-bar-group {
      display: flex;
      flex-direction: row;
      align-items: center;

      > *:first-child {
        margin-right: var(--bfs12);
      }

    }


  }
  @media (max-width: 760px) {
    .button-bar {
      flex-direction: column;

      .button-bar-group {
        margin-top: var(--bfs12);
      }

    }
  }

  .network-details-content {

    > * {
      margin-top: var(--bfs12);
    }

  }

  .bottom-line {
    display: flex;
    justify-content: space-between;

    .table-size-dropdown {
      margin-top: var(--bfs8);
    }

    .row-box {
      display: flex;

      > div {
        width: 75px;
        margin-left: var(--bfs8);
        margin-top: var(--bfs8);
      }
    }

  }

}