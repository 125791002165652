.backoffice-editable-container {

  .service-settings-container {
    display: flex;
    flex-direction: column;
    background: var(--bfc-base-3);
    border-radius: 11px;


    .service-settings-header {
      padding: var(--bfs24);
      border-bottom: 1px solid var(--bfc-base-dimmed);

      > p {
        margin: 0;
      }

      > h5 {
        margin: 0;
        margin-bottom: var(--bfs4);
      }

    }

    .service-settings-body {
      padding: var(--bfs24);
      display: flex;
      flex-direction: column;

      > div {
        margin-bottom: var(--bfs12);
      }

      > table {
        border: 1px solid var(--bfc-base-dimmed);
        border-radius: 11px;
      }

    }

  }

}
