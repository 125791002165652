.computer-reg-box-item {
  display: flex;
  border-radius: 11px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--bfc-base-3);

  > div {
    //width: 50%;
  }

  .computer-reg-box-item-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px 0 0 11px;

    > h3 {
      color: var(--bfc-chill-c);
      margin: 0 30px;
    }
  }


  .computer-reg-box-item-body {
    overflow: auto;
    padding: var(--bfs12);
    border-radius: 0 11px 11px 0;

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;

      > svg {
        margin-right: var(--bfs4);
      }

      > h5 {
        margin: 0;
      }

    }

    > p {
      margin: 0 0 var(--bfs8);
    }


  }


}