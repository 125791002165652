.information-box {
  padding: var(--bfs24);
  background: var(--bfc-base-3);
  min-height: 90px;
  display: flex;
  flex-direction: row;

  .image-container {

    > svg {
      color: var(--bfc-base-c-2);
    }

    > img {
      height: 21px;
    }

  }

  .information-box-content {
    flex: 1;

    > span {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      justify-content: space-between;

      > svg {
        cursor: pointer;
      }

    }
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: auto;
    margin-top: auto;
    //color: var(--bfc-base-c-2);

    > * {
      margin: 0;
    }

    > h5 {
      color: var(--bfc-base-c-2);
      margin-left: var(--bfs4);
      text-transform: uppercase;
    }

  }

  > div {
    display: flex;
    height: 100%;
  }

  > div:first-child {
    border-right: 1px solid var(--bfc-base-c-dimmed);
    padding-right: var(--bfs24);
  }
  > div:last-child {
    padding-left: var(--bfs24);
  }


}