.new-user {
  padding: var(--bfs40);
  max-width: 1200px;

  .new-user-header {
    display: flex;
    flex-direction: row;

    > h2 {
      margin-top: 0;
      margin-right: var(--bfs12);
    }

  }

  .user-group {

    > div {
      max-width: 250px;

    }

  }

  .new-user-buttons {
    margin-top: var(--bfs40);
    display: flex;
    justify-content: space-between;

    button:last-child {
      margin-left: var(--bfs16);
    }


  }


}