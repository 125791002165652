.bf-accordion-item-title {
  overflow: unset;

  .accordion-title {
    width: 100%;
    display: inline-block;

    .accordion-title-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 31px;

      > svg {
        color: var(--bfc-base-c-success);
      }

      > p {
        margin: 0;
      }

      p:last-child {
        color: var(--bfc-base-c-2);
      }

    }

  }

}

.bf-accordion-item-content {

  .communication-row {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: var(--bfs12) var(--bfs24);
    border-bottom: 1px solid var(--bfc-base-c-dimmed);

    .communication-row-info {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 100%;
        margin-right: var(--bfs12);
        text-align: center;
      }
    }
  }

}
