
.domain-comment {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--bfc-base-dimmed);
  margin: var(--bfs8) 0;

  > p {
    margin: 0;
  }

  .comment-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: var(--bfs8);
    color: var(--bfc-base-c-2);

    > span {
      margin: 0 0 0 var(--bfs8);

      > svg {
        margin-right: var(--bfs4);
      }

    }

    > p {
      margin: 0;
    }

    > img {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: var(--bfs4);
    }
  }

  .comment-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .comment-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: var(--bfs8);

      > span {

        > svg {
          margin-right: var(--bfs4);
        }
      }

      > span:first-of-type {
        color: var(--bfc-base-c-alert);
      }

      > span:last-of-type {
        color: var(--bfc-base-c-theme);
        margin-left: var(--bfs16);
      }

    }

  }


}