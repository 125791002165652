#drawer-publish-article {

  div {
    padding-left: 0;
    padding-right: 0;

    .bf-message {
      padding-left: var(--bfs12);
      padding-right: var(--bfs12);
    }
  }

  .article-content-container {
    padding-top: 0;
  }

}