#overview {
  .image-container {
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;

    > img {
      height: 300px;
      z-index: -1;
      position: absolute;
      top: 0;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }

    @media (min-width: 1580px) {
      > img {
        width: 100%;
      }
    }
  }

  .content {
    display: flex;
    min-height: calc(100vh - 56px);

    .content-main {
      width: 100%;
      margin: 0 auto;
      max-width: 1170px;
      padding-top: 120px;
      padding-bottom: 100px;
      padding-left: 0px;
      padding-right: 0px;

      @media screen and (max-width: 600px) {
        padding-top: 40px;
      }

      .first-page {
        display: flex;
        flex-direction: column;
        align-content: center;

        .text-content {
          display: flex;
          flex-direction: row;
          margin-bottom: var(--bfs40);

          @media screen and (max-width: 600px) {
            justify-content: center;
            // padding-top: 40px;
          }

          > div:first-of-type {
            margin-right: var(--bfs24);
          }

          > div {
            > h1 {
              color: #fff;
              margin-bottom: 0;
              margin-top: 0;
            }
            > h5 {
              color: #fff;
              margin-bottom: 0;
              margin-top: 0;
            }
          }
        }

        .box-content {
        }

        .footer {
          position: absolute;
          top: calc(100vh - 140px);
          left: calc(50vw - 36px);
          display: flex;
          justify-content: center;
        }

        .footer {
          > h1 {
            > svg:hover {
              cursor: pointer;
              transition: all 0.3s ease 0s;
              transform: scale(1.5);
            }
          }
        }
      }

      > div {
        margin: var(--bfs24) 0;
      }
      > div:first-of-type {
        margin-top: 0;
      }
      > div:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        width: 60%;
      }
    }
  }
}
