
.microsoft365-box-item {
  position: relative;
  padding: var(--bfs40);
  align-items: center;
  gap: var(--bfs24);
  align-self: stretch;
  max-width: 1170px;
  --bfl-columns: 2;

  border-radius: var(--bf-radius-m);
  background: var(--bfc-theme-c-1);
  box-shadow: 0px 4px 8px 0px var(--bfc-shadow);

  .microsoft365-box-item-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    > img {
      display: flex;
      height: 292px;
      padding: 0px 20px 0.018px 21px;
      justify-content: center;
      align-items: center;

    }
  }


  .microsoft365-box-item-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    > h5 {
      margin-bottom: var(--bfs8);
    }

    > h1 {
      margin-top: 0;
      margin-bottom: 0;
    }

    > div {
      margin-top: var(--bfs24);
    }

  }

  .microsoft365-box-item-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }


}

@media (max-width: 960px) {
  .microsoft365-box-item {
    --bfl-columns: 1;
  }
}