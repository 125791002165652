#communication {

  .content {
    display: flex;

    .content-main {
      width: 100%;

      .communication-box {
        display: flex;
        flex-direction: column;

        .box-list {
          --bfl-columns: 1;
          --bfl-gap: var(--bfs8);
        }
        @media (min-width: 600px) {
          .box-list {
            --bfl-columns: 2;
          }
        }
        @media (min-width: 960px) {
          .box-list {
            --bfl-columns: 4;
          }
        }

        .content-main-search {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            margin: 0;
            font-size: var(--bf-font-size-m);
          }

        }


      }
    }


  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        width: 80%;
      }
    }
  }

}
