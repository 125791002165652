.basic-hub-page {


  .box-header {
    display: flex;
    flex-direction: column;
    height: 40px;
    overflow: auto;
    position: absolute;
    top: 17px;
    width: 100%;
    z-index: 100;

    > div {
      height: 17px;
      border-top: 1px solid var(--bfc-base-dimmed);
    }

    > button {
      cursor: pointer;
      position: absolute;
      right: 0;
      padding: var(--bfs8) var(--bfs16);
      margin-right: var(--bfs40);
      font-size: var(--bf-font-size-s);
      white-space: nowrap;
      background: transparent;
      color: var(--bfc-base-c-theme);
      align-items: center;
      display: flex;
      font-weight: 400;
      line-height: 150%;
      background: var(--bfc-base-2);
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: var(--bfc-base-dimmed);
      border-radius: 0 0 3px 3px;
    }


  }

  .box-dropdown {
    --bfl-columns: 2;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: var(--bfc-base-2);
    height: 100px;


    > div {
      flex: 1 2;
    }

    .box-dropdown-info {
      display: flex;
      flex-direction: row;
      margin-right: var(--bfs24);

      .box-info-text {
        flex: 1 2;
        max-width: 460px;
        margin: var(--bfs40);
        margin: auto;
        //margin-right: 0;
        text-align: center;
        display: flex;

        > div {
          display: flex;
          margin: auto;
          flex-direction: column;
          justify-content: center;

          > h3 {
            margin-top: 0;
          }

          > p {
            margin: 0;
          }
        }


      }

      .box-info-article {
        margin: var(--bfs40) var(--bfs12);
        background: var(--bfc-base-3);
        border: 1px solid var(--bfc-base-dimmed);
        border-radius: 10px;
      }

      @media (max-width: 1280px) {
        .box-info-article {
          margin-left: 0;
          margin-right: 0;
        }
      }


    }


    .box-dropdown-tasks {


      > div {
        margin: var(--bfs40);
        margin-right: var(--bfs12);
        border-radius: 3px;

        .content-box {

          .task-container-content {
            display: flex;
            flex-direction: row;
            max-height: 261px;
            height: 261px;
            box-sizing: content-box;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            overflow: hidden;

            .task-container-tasks {
              width: 100%;

              .header-buttonbar {
                padding: var(--bfs8);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                > h5 {
                  margin: 0;
                }

                > span {
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  > svg {
                    margin-left: var(--bfs8);
                  }

                  > h5 {
                    margin: 0;
                  }
                }

                > span:hover {
                  text-decoration: underline;
                }

                .dropdown-checkboxes {
                  display: flex;
                  flex-direction: column;
                  width: 100px;
                }

                .topbar-level-dropdown {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  cursor: pointer;

                  > h5 {
                    user-select: none;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    /* or 24px */
                    margin: 0 var(--bfs6) 0 auto;
                    display: flex;
                    align-items: center;
                  }

                  > svg {
                    margin: auto 0;
                  }

                }

              }

              .task-list {
                height: 205px;
                overflow-y: scroll;
                position: relative;
                scrollbar-color: var(--bfc-base-c-wcag) transparent;
                scrollbar-width: thin;
                white-space: nowrap;

                .tasks-complete-image {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  > img {
                    height: 110px;
                    width: 110px;
                  }

                }

                .onboard-task {
                  border: 1px solid var(--bfc-base-dimmed);
                  background-color: var(--bfc-base-3);
                }

                .onboard-task:hover {
                  background-color: var(--bfc-theme-c-1);
                }

                > div {
                  padding: var(--bfs8);
                  margin: var(--bfs8);
                }

                > p {
                  padding: var(--bfs4) var(--bfs12);
                  margin: 0;
                  background-color: var(--bfc-base-2);
                  border-bottom: 1px solid var(--bfc-base-dimmed);
                }


              }

            }

            .task-container-level {
              display: flex;
              flex-direction: column;
              border-left: 1px solid var(--bfc-base-dimmed);
              padding: var(--bfs16) var(--bfs12);
              min-width: 80px;
              width: 80px;

              > p {
                margin: 0;
                margin-top: var(--bfs8);
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: var(--bfc-base-c-2);
                text-align: center;
              }

            }


          }


        }

      }


    }

  }

  @media (max-width: 1279px) {
    .box-dropdown {
      --bfl-columns: 4;

      .box-dropdown-info {
        margin-top: var(--bfs40);
        display: block;
        grid-column: span 1;
      }

      .box-dropdown-tasks {
        grid-column: span 3;
      }

    }
  }
  @media (max-width: 960px) {
    .box-dropdown {
      --bfl-columns: 3;

      .box-dropdown-info {
        grid-column: span 1;
      }

      .box-dropdown-tasks {
        grid-column: span 2;
      }

    }
  }
  @media (max-width: 600px) {
    .box-dropdown {
      --bfl-columns: 1;

      .box-dropdown-info {
        display: flex;
      }

    }
  }


}


#task-top-bar-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-bottom: var(--bfs12);
    width: 100%;

    > button {
      flex: 1;
    }

    .tasks-complete-image {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  .task-list {
    height: 60vh;
    overflow-y: scroll;
    position: relative;
    scrollbar-color: var(--bfc-base-c-wcag) transparent;
    scrollbar-width: thin;

    .tasks-complete-image {
      display: flex;
      flex-direction: column;
      align-items: center;

      > img {
        height: 110px;
        width: 110px;
      }

    }

    .onboard-task {
      border: 1px solid var(--bfc-base-dimmed);
      background-color: var(--bfc-base-3);

      .task-text {}

    }

    > div {
      padding: var(--bfs8);
      margin: var(--bfs8);
    }

    > p {
      padding: var(--bfs4) var(--bfs12);
      margin: 0;
      background-color: var(--bfc-base-2);
      border-bottom: 1px solid var(--bfc-base-dimmed);
    }
  }

}
