.settings-modal {
  overflow: scroll;
}

.settings-content {
  --bfl-columns: 2;
  --bfl-gap: var(--bfs24);
  min-height: 410px;

  .settings-box {
    border-radius: 5px;

    .settings-box-header {

      > p {
        margin: 0;
      }

    }
  }

  .settings-added-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > h1 {
      margin-bottom: 0;
      margin-top: 0;
    }

    > div {
      > a {
        margin-right: var(--bfs8);
      }
    }

  }

  .team-member-list {
    max-height: 410px;
    overflow-x: scroll;

  }

    .settings-box-content {
      padding: var(--bfs8) 0 var(--bfs16) 0;
    }


    .add-user {
      display: flex;
      flex-direction: column;

      .input-wrapper {
        display: inline-block;
        margin: 0 var(--bfs12);
      }

      > div {
        display: flex;
        align-items: center;

        .respo-dropdown {

          * {
            z-index: 100;
          }

        }

      }

      > div:last-of-type {
        margin-bottom: var(--bfs12);
      }

      > button {
        margin-top: auto;
      }
    }

    .input-wrapper {
      margin-left: var(--bfs12);
      margin-right: var(--bfs12);
    }

  .edit-profile {
    display: flex;
    flex-direction: column;

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;

      > svg {
        margin-right: var(--bfs8);
      }

      > p {
        margin: 0;
      }

    }

    .profile-content {
      padding: var(--bfs40) 100px;
      display: flex;
      flex-direction: column;
      margin: auto;
      justify-items: center;
      align-items: center;

      > p {
        margin: 0;
        margin-bottom: var(--bfs8);
      }

      > h4 {
        margin: 0;
      }

      .editor-card-image {
        display: flex;
        height: 112px;
        width: 112px;
        border-radius: 3%;

        > img {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: var(--bfs12);
          height: 112px;
          width: 112px;
          border-radius: 50%;
        }

        > div {
          display: flex;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: var(--bfs12);
          height: 112px;
          width: 112px;
          border-radius: 50%;
          background-color: var(--bfc-base-dimmed);

          > svg {
            margin: auto;
            height: 50%;
            width: 50%;
            border-radius: 50%;
            color: var(--bfc-base-c-2);
          }

        }

      }

    }


  }

  .edit-profile-fields {
    display: flex;
    flex-direction: column;

    > button {
      align-self: flex-end;
      margin-top: auto;
    }

  }

}

@media(max-width: 1000px) {
  .settings-content {
    --bfl-columns: 1;
  }
}

