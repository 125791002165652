.application-contact-box {

  .accordion-item {

    .bf-accordion-item-title {

      > button {
        display: flex;
        flex-direction: row;
        align-items: center;

        > span:last-of-type {
          width: 100%;
        }

        .accordion-header {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .badge-container {

            > div {
              margin-right: var(--bfs4);
            }

            div:last-child {
              margin-right: 0;
            }

          }

          > p {
            margin-top: auto;
            margin-bottom: auto;
          }

        }
      }

      h5 {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }



  }

  .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h3 {
      margin: 0;
    }
  }

  .box-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    svg {
      cursor: pointer;
      color: var(--bfc-base-c-theme);
    }

    h5 {
      margin: 0;
    }

    p {
      margin: 0;
    }

  }

  > p {
    margin-bottom: var(--bfs8);
    margin-top: var(--bfs8);
    color: var(--bfc-base-c-2);
  }

}