#users {

  .content-main {
    padding: 0 var(--bfs40) var(--bfs40);
    padding-top: var(--bfs40);
    width: 100%;

    .box-container {
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      .users-box {
        flex-direction: column;

        .users-box-buttons {
          --bfl-columns: 1;
          --bfl-gap: var(--bfs16);
        }
        @media (min-width: 700px) {
          .users-box-buttons {
            --bfl-columns: 2;
          }
        }
        @media (min-width: 1360px) {
          .users-box-buttons {
            --bfl-columns: 6;
          }
        }

        .users-box-header {
          margin-top: var(--bfs16);
          padding-bottom: var(--bfs16);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: var(--bfc-base);
          top: 56px;

          .users-box-header-buttons {
            margin-top: auto;

            > button:first-of-type {
              margin-right: var(--bfs8);
            }

          }

          .select-group {
            display: flex;
            flex-direction: row;

            > button {
              margin-top: auto;
            }

            .button-group {
              margin-top: auto;

              > button:first-of-type {
                margin-right: var(--bfs8);
                margin-left: var(--bfs4);
              }

            }

            .select-group-select {
              margin-left: var(--bfs12);
              width: 150px;


              > div {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
              }

            }

            .select-group-filler {
              background-color: var(--bfc-base-3);
              padding: 4px 1px;
              border-bottom: 2px solid var(--bfc-base-c-wcag);

              > div {
                background: var(--bfc-base-dimmed);
                width: 1px;
                height: 100%;
              }

            }

            .select-group-input {
              min-width: 200px;
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }

            .users-item-highlight {
              z-index: 999;
              background-color: var(--bfc-base);
              padding: var(--bfs4);
              border-radius: 3px;
            }

            .users-item {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
            }

            .users-box-select {
              margin-top: auto;
              min-width: 250px;
              z-index: 2;
              margin-right: var(--bfs4);

            }
          }
        }
        .missing-info-bar {
          margin-top: var(--bfs24);
          display: flex;
          padding: var(--Spacing-2, 16px) var(--Spacing-3, 24px);
          align-items: center;
          align-content: center;
          justify-content: space-between;
          gap: var(--Spacing-2, 16px) 8px;
          align-self: stretch;
          flex-wrap: wrap;
          border-radius: var(--Border-radius-M, 12px);
          background: var(--bfc-base-3);
          box-shadow: var(--Colors-Effect-variables-Shadows-Axis-None, 0px) var(--Colors-Effect-variables-Shadows-Axis-XS, 4px) var(--Colors-Effect-variables-Shadows-Blur-S, 8px) var(--Colors-Effect-variables-Shadows-Spread-None, 0px) var(--Colors-Effect-variables-Shadows-Opacity-100, rgba(0, 0, 0, 0.10));

          > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            > h5 {
              margin: 0;
            }

          }


        }

        .users-table {
          position: relative;
          white-space: nowrap;
          margin-top: var(--bfs24);
          border-radius: var(--Border-radius-S, 8px);
          background: var(--bfc-base-3);
          box-shadow: var(--Colors-Effect-variables-Shadows-Axis-None, 0px) var(--Colors-Effect-variables-Shadows-Axis-XS, 4px) var(--Colors-Effect-variables-Shadows-Blur-S, 8px) var(--Colors-Effect-variables-Shadows-Spread-None, 0px) var(--Colors-Effect-variables-Shadows-Opacity-100, rgba(0, 0, 0, 0.10));


          .users-filter-row {
            display: flex;
            flex-direction: column;
            padding: var(--Spacing-2, 16px) var(--Spacing-3, 24px);
            background: var(--bfc-base-3);
            border-bottom: 1px solid var(--bfc-base-dimmed);
            border-top-left-radius: var(--Border-radius-S, 8px);
            border-top-right-radius: var(--Border-radius-S, 8px);


            .users-filter-row-filters {
              margin-top: var(--bfs16);
              text-wrap: auto;
              display: flex;
              flex-direction: row;


              > * {
                margin: var(--bfs8);
                margin-left: 0;
                margin-top: 0;
              }

              .add-button {
                align-self: center;
                margin: 0 0 0 auto;
                cursor: pointer;
                font-size: var(--bf-font-size-m);
                white-space: nowrap;
                background: transparent;
                border: 0;
                align-items: center;
                color: var(--bfc-base-c);
              }

              .add-button:hover {
                background-color: var(--bfc-theme-c-base-3);
                border-radius: 3px;
              }

              .add-button:focus {
                outline: none;
              }

              .add-button:focus-visible {
                border-radius: 3px;
                outline: 1px dashed var(--bff-theme);
                -moz-outline-radius: var(--bfl-border-radius);
                position: relative;
              }

            }

            .users-filter-row-actions {
              display: flex;
              flex-wrap: wrap;
              margin-top: var(--bfs8);

              > fieldset {
                margin-right: auto;
                flex: 1;
                min-width: 320px;
                max-width: 420px;
              }

              .small-select-group {

                > div {
                  margin-bottom: var(--bfs8);
                }
                > div:last-of-type {
                  margin-bottom: 0;
                }

              }

              .select-group {
                display: grid;
                --bfl-gap: var(--bfs12);
                flex-direction: row;
                grid-column: span 1;
                margin-left: initial;
                //z-index: 9;
                --bfl-columns: 4;

                .select-group-select {
                  //min-width: 250px;


                  > div {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                  }

                }

              }
              @media (max-width: 990px) {
                .select-group {
                  //display: flex;
                  --bfl-columns: 3;
                  margin-right: auto;
                }
              }
              @media (max-width: 720px) {
                .select-group {
                  //display: flex;
                  --bfl-columns: 2;
                  margin-right: auto;
                }
              }
              @media (max-width: 550px) {
                .select-group {
                  --bfl-columns: 1;
                  margin-right: unset;
                }
              }

              .select-group-select {
                min-width: 150px;
                z-index: 2;


                > div {
                  border-bottom-right-radius: 0;
                  border-top-right-radius: 0;
                }

              }

              .filter-field-group {
                display: flex;
                margin-right: auto;
                div {
                  z-index: 5;
                }
              }
              @media (max-width: 2000px) {
                .filter-field-group {

                }
              }

              .bf-select__menu {
                z-index: 5;

                * {
                z-index: 5;

                }

              }


            }
            @media (max-width: 500px) {
              .users-filter-row-actions {
                display: grid;
                --bfl-columns: 1;
              }
            }

          }

          .company-users-edit-row {
            background: var(--bfc-base-2);
            animation: ease-scale 0.3s ease-in-out;
            -webkit-animation: ease-scale 0.3s ease-in-out;
            -moz-animation: ease-scale 0.3s ease-in-out;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid var(--bfc-base-dimmed);

            @-moz-keyframes ease-scale {
              0% { height: 0}
              100% { height: 64px }
            }
            @-webkit-keyframes ease-scale {
              0% { height: 0}
              100% { height: 64px }
            }
            @keyframes ease-scale {
              0% { height: 0}
              100% { height: 64px }
            }



            > div {
              padding: var(--bfs12);

              div {
                .bf-select__menu {
                  z-index: 5;
                }
              }

            }

            > div:first-of-type {
              flex-grow: 1;
              --bfl-columns: 6;
              border-right: 1px solid var(--bfc-base-dimmed);
              --bf-autocol-width: 180px;

            }

            .company-users-edit-row-dropdowns {
              display: flex;
              flex-wrap: wrap;

              > div {
                min-width: 170px;
                max-width: 370px;
                flex: 1;
              }

            }

            .company-users-edit-row-buttons {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: auto;
            }
            @media (max-width: 2037px) {
              .company-users-edit-row-buttons {
                //--bfl-columns: 3;
              }
            }
            @media (max-width: 1191px) {
              .company-users-edit-row-buttons {
                --bfl-columns: 2;
              }
            }
            @media (max-width: 960px) {
              .company-users-edit-row-buttons {
                --bfl-columns: 4;
              }
            }
            @media (max-width: 750px) {
              .company-users-edit-row-buttons {
                --bfl-columns: 3;
              }
            }
            @media (max-width: 550px) {
              .company-users-edit-row-buttons {
                --bfl-columns: 2;
              }
            }


          }

          .company-users-edit-row.fade-out {
            height: 0;
            animation: ease-out-scale 0.3s ease-in-out;
            -webkit-animation: ease-out-scale 0.3s ease-in-out;
            -moz-animation: ease-out-scale 0.3s ease-in-out;
          }

          @-moz-keyframes ease-out-scale {
            0% { height: 64px}
            100% { height: 0 }
          }
          @-webkit-keyframes ease-out-scale {
            0% { height: 64px}
            100% { height: 0 }
          }
          @keyframes ease-out-scale {
            0% { height: 64px}
            100% { height: 0 }
          }

          @media (max-width: 960px) {
            .company-users-edit-row {
              flex-direction: column;
            }
          }

          .users-table-tab-content-deleted {
            overflow: scroll;
            max-height: 70vh;

            table {
              position: relative;
              overflow: scroll;
              scrollbar-color: var(--bfc-base-c-wcag) transparent;
              scrollbar-width: thin;
            }

            thead {
              position: -webkit-sticky;
              position: sticky;
              top: 0;
              z-index: 3;
              background-color: var(--bfc-base-3);

              tr {

                th {
                  position: relative;
                  border-bottom: 1px solid var(--bfc-base-dimmed);
                }

                th:first-child {
                  background: var(--bfc-base-3);
                  position: -webkit-sticky;
                  position: sticky;
                  z-index: 1;
                  left: 0;
                  border-bottom: 1px solid var(--bfc-base-dimmed);

                  div:first-child {
                    //width: 175px;

                    input {
                    }

                    label {

                      > span {
                        font-weight: 600;
                      }

                    }


                  }


                }
              }

            }

            tbody {

              tr {

                td {
                  height: 49px;
                  padding-top: var(--bfs4);
                  padding-bottom: var(--bfs4);
                }

                td:first-child {
                  background: var(--bfc-base-3);
                  position: -webkit-sticky;
                  position: sticky;
                  z-index: 1;
                  left: 0;
                  height: 49px;


                  .user-row-first-cell {
                    display: flex;
                    justify-content: space-between;

                    > svg {
                      margin-top: auto;
                      margin-bottom: auto;
                    }

                    > div {
                      display: flex;
                      margin: auto 0;
                    }

                    div:first-child {

                      input {
                      }

                      label {
                        padding-right: var(--bfs4);
                        overflow-x: hidden;
                      }


                    }
                  }


                }

              }
            }
          }

          .users-table-tab-content {
            overflow: scroll;
            max-height: 70vh;

            table {
              position: relative;
              overflow: scroll;
              scrollbar-color: var(--bfc-base-c-wcag) transparent;
              scrollbar-width: thin;
            }

            thead {
              position: -webkit-sticky;
              position: sticky;
              top: 0;
              z-index: 3;
              background-color: var(--bfc-base-3);

              tr {

                th {
                  position: relative;
                  border-bottom: 1px solid var(--bfc-base-dimmed);
                }

                th:first-child {
                  background: var(--bfc-base-3);
                  position: -webkit-sticky;
                  position: sticky;
                  z-index: 1;
                  left: 0;
                  //border-right: 1px solid var(--bfc-base-dimmed);
                  border-bottom: 1px solid var(--bfc-base-dimmed);

                  div:first-child {
                    //width: 175px;

                    input {
                    }

                    label {

                      > span {
                        font-weight: 600;
                      }

                    }


                  }


                }

              }

            }

            tbody {

              tr {

                td {
                  height: 49px;
                  padding-top: var(--bfs4);
                  padding-bottom: var(--bfs4);

                  > * {
                    flex-direction: column;
                    margin-bottom: auto;

                  }

                }

                td:first-of-type {
                  background: var(--bfc-base-3);
                  position: -webkit-sticky;
                  position: sticky;
                  z-index: 1;
                  left: 0;
                  height: 49px;

                  .user-row-first-cell {
                    display: flex;
                    justify-content: space-between;

                    > svg {
                      margin-top: auto;
                      margin-bottom: auto;
                    }

                    > div {
                      display: flex;
                      margin: auto 0;
                    }

                    div:first-child {

                      input {
                      }

                      label {
                        padding-right: var(--bfs4);
                        overflow-x: hidden;
                      }


                    }
                  }


                }


              }

            }

          }


        }

        .bottom-line {
          display: flex;
          justify-content: space-between;
          padding: 4px var(--Spacing-3, 24px);
          border-top: 1px solid var(--bfc-base-dimmed);

          .row-box {
            display: flex;

            > div {
              width: 75px;
              margin-left: var(--bfs8);
              margin-top: var(--bfs8);
            }
          }

        }

        .spinner-container {
          margin-top: var(--bfs52);
          display: flex;
          justify-content: center;
        }

      }


    }
  }

  .ellipsis-group {

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    > span:hover {
      > p {
        color: var(--bfc-base-c-theme);
      }
    }

  }

  .content-main.full-screen {
    padding: 0 var(--bfs24);
  }

  @media (max-width: 1600px) {
    .content-main {
      padding: 0 var(--bfs24) 0 var(--bfs24);
    }
  }
  @media (max-width: 600px) {
    .content-main {
      padding: 0 var(--bfs12) 0 var(--bfs12);
    }
  }

}

.users-box-modal {
  display: flex;
  justify-content: flex-end;

  button:first-child {
    margin-right: var(--bfs8)
  }

}

.guide-dropdown-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.guide-dropdown-margin {
  margin-left: var(--bfs24);
}

.guide-dropdown {
  z-index: 9999;
  width: 350px;

  .guide-dropdown-content {

    > p:first-of-type {
      margin: 0;
    }

    > p:last-of-type {
      margin-top: var(--bfs4);
    }

    .guide-dropdown-button-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: row;

        > p {
          margin: 0 var(--bfs8) 0 0;
          align-self: center;
        }

      }
    }
  }

}
