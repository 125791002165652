#contact-us {

  .header-container {
    display: flex;
    justify-content: space-around;
    background: var(--bfc-base-2);
    margin-bottom: var(--bfs24);
    border-bottom: 1px solid var(--bfc-base-dimmed);

    .contact-us-header {
      padding: var(--bfs40);

    }

    @media (min-width: 1920px) {
      .contact-us-header {
        width: 60%;
      }
    }

    @media (max-width: 1919px) {
      .contact-us-header {
        width: 80%;
      }
    }

    @media (max-width: 1280px) {
      .contact-us-header {
        width: 100%;
        padding: 0 var(--bfl-page-padding);
      }
    }

  }

  .content {
    height: 100%;
    display: flex;
    justify-content: space-around;

    .page-content {
      display: flex;
      position: relative;
      flex-direction: row;

      .main-content {
        position: relative;
        flex: auto;

        .contact-us-container {


        }
      }

      .side-menu {
        margin-left: var(--bfs24);
        padding: var(--bfs24);
        border-radius: 11px;
        width: 382px;
        display: flex;
        flex-direction: column;
        height: fit-content;
        gap: 8px;

        > p:first-of-type {
          color: var(--bfc-base-c-2);
        }

        > p {
          margin: 0;
          color: var(--bfc-base-c-theme);
        }

        > a {
          margin: 0;
        }

        > a:hover {
          text-decoration-line: underline;
          -moz-text-decoration-line: underline;
        }

      }




    }

    @media (min-width: 1920px) {
      .page-content {
        width: 60%;
      }
    }

    @media (max-width: 1919px) {
      .page-content {
        width: 80%;
      }
    }

    @media (max-width: 1280px) {
      .page-content {
        width: 100%;
        padding: 0 var(--bfl-page-padding);
      }
    }



  }



}