#intility-services {


  .header-container {
    display: flex;
    justify-content: space-around;
    background: var(--bfc-base-2);

    .service-header {

    }

    @media (min-width: 1920px) {
      .service-header {
        width: 60%;
      }
    }

    @media (max-width: 1919px) {
      .service-header {
        width: 80%;
      }
    }

    @media (max-width: 1280px) {
      .service-header {
        width: 100%;
        padding: 0 var(--bfl-page-padding);
      }
    }

  }

  .content {
    height: 100%;
    display: flex;
    justify-content: space-around;

    .page-content {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: space-between;


      .service-container {
        flex: 1;

        > div {
          margin-bottom: var(--bfs16);
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
        }

      }

      .filter-container {
        margin-left: var(--bfs24);
        min-width: 334px;
        display: flex;
        flex-direction: column;

        .filter-search {
          background: var(--bfc-base-3);
          padding: var(--bfs16) var(--bfs24);
          border-radius: 11px;
          margin-bottom: var(--bfs24);
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
        }

        .filter-buttons {
          padding: var(--bfs24);
          border-radius: 11px;
          background: var(--bfc-base-3);
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
          display: flex;
          flex-direction: column;
        }


      }

      @media (max-width: 960px) {
        .filter-container {
          margin-left: 0;
        }
      }


    }

    @media (min-width: 1921px) {
      .page-content {
        width: 60%;
        padding: var(--bfl-page-padding) 0;
      }
    }

    @media (max-width: 1919px) {
      .page-content {
        width: 80%;
        padding: var(--bfl-page-padding) 0;
      }
    }

    @media (max-width: 1280px) {
      .page-content {
        width: 100%;
        padding: var(--bfl-page-padding);
      }
    }

    @media (max-width: 960px) {
      .page-content {
        flex-direction: column-reverse;
      }
    }


  }


}

.services-filter-modal {

  .services-filter-modal-buttons {
    display: flex;

    > button {
      margin-top: var(--bfs12);
      flex: 1;
    }

  }

  .filter-buttons {
    padding: var(--bfs24);
    border-radius: 11px;
    background: var(--bfc-base-3);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
  }

}
