$imageWidth: 250px;
$imageHeight: 150px;
$borderWidth: 2px;

.wallpaper-picker {
  flex: 1;

  .wallpaper-picker-title {
    margin-bottom: var(--bfs12);
  }

  .upload-button {
    position: relative;

    .file-input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  .wallpaper-picker-container {
    display: flex;
    flex-wrap: wrap;
    margin: var(--bfs12) var(--bfs12) var(--bfs12) 0;
  }

  .wallpaper-picker-button-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > div:first-of-type {
      margin-right: var(--bfs8);
    }

  }

}
