#new-company {
  max-width: 1048px;
  //min-width: 648px;
  width: stretch;
  padding: var(--bfs24);


  .company-box {
    --bfl-columns: 1;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));

    .register-image {
      display: flex;
      position: relative;
      height: 250px;
      border-radius: 10px 10px 0 0;

      .image {
        width: 100%;
        border-radius: 10px 10px 0 0;
        display: block;
        object-fit: cover;
      }

    }

    .register-content {
      background-color: var(--bfc-base-2);
      padding: var(--bfs24);
      display: flex;
      flex-direction: column;
      border-radius: 0 0 10px 10px;

      .register-content-text {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--bfs24);

        .register-message {
          margin-bottom: var(--bfs24);
        }

        > p {
          margin-top: 0;
        }

        .error-message {
          display: flex;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 12px;
          border: 1px solid var(--bfc-base-dimmed);
          background: var(--bfc-base-3);

          .error-message-buttons {
            display: flex;
            flex-direction: row;
            border-top: 1px solid var(--bfc-base-dimmed);
            padding-top: 12px;
            align-items: center;
            gap: 8px;
            align-self: stretch;

            > button {
              min-width: 106px;
            }

            > p {
              font-size: 12px;
              font-style: italic;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }

          }

          > h5 {
            margin: 0;
          }

          > div {
            width: 100%;
          }

          > span {
            margin: 0;
          }

          p {
            margin: 0;
          }

        }

        .register-input {
          margin-bottom: var(--bfs16);
        }

        .register-input:last-of-type {
          margin-bottom: 0;
        }

        > img {
          color: var(--bfc-base-c-theme);
          margin-left: auto;
        }

        h1 {
          font-weight: 500;
          margin-top: 0;
          margin-bottom: var(--bfs12);
        }

        .review-box {
          background-color: var(--bfc-base-1);
          border-radius: 10px;
          padding: var(--bfs16);

          > p {
            margin-top: 0;
            margin-bottom: var(--bfs4);
          }

          > span {
            display: flex;

            > p {
              margin-top: 0;
              margin-bottom: var(--bfs4);
            }

          }

        }

      }

      .register-buttons {
        margin-top: auto;
        justify-content: flex-end;
        --bfl-columns: 2;

      }

    }

  }

  @media (min-width: 600px) {
    .company-box {
      display: flex;
      --bfl-columns: 2;
      --bfl-gap: var(--bfs40) var(--bfs16);

      > div {
        //width: 500px;
        max-width: 500px;
        height: 500px;
      }

      .register-image {
        border-radius: 10px 0 0 10px;
        height: 500px;
        .image {
          border-radius: 10px 0 0 10px;
        }
      }

      .register-content {
        border-radius: 0 10px 10px 0;
      }

    }
  }

  .button-container {
    display: flex;
    justify-content: right;
    margin-bottom: var(--bfs40);
    margin-top: var(--bfs24);
    > * {
      flex: 1;
    }
  }
}

