#printer-modal {

  .body {


    > div {
      --bfl-columns: 2;
    }

  }

  .button-row {
    display: flex;
    margin-top: var(--bfs24);
    justify-content: space-between;
  }

}