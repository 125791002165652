#documentation {
  .content-main {

      .documentation-box {
        display: flex;
        flex-direction: column;

        > textarea {
          min-height: 400px;
        }
        .documentation-box-files {

          .documentation {
            font-size: var(--bf-font-size-m);
            margin-right: var(--bfs8);
            display: inline;
          }

          > span {
            margin-right: var(--bfs16);

            > svg {
              color: var(--bfc-alert);
              cursor: pointer;
            }

          }

          > span:last-child {
            margin-right: 0;
          }

        }

        .documentation-app-container {


          .documentation-app-entry {

            > span {
              display: flex;
              justify-content: space-between;
              flex-direction: row;

              > a {
                color: var(--bfc-base-c-1);
                margin: var(--bfs16) 0;
              }

            }

            > div {
              padding: var(--bfs12);
              display: flex;
              flex-direction: column;
              background-color: var(--bfc-base-2);

              > span {
                display: flex;
                flex-direction: row;

                > p {
                  color: var(--bfc-base-c-1);
                  margin: 0 0 var(--bfs12);
                }

                > svg {
                  height: 21px;
                  margin: auto var(--bfs4) var(--bfs12) 0;
                }

              }

            }

          }


        }


      }



  }
}

