.guide-modal {

  > p {
    margin: 0;
  }

  > header {
    display: flex;

    > svg {
      font-size: 36px;
      font-weight: 300;
      margin-top: auto;
      margin-bottom: auto;
    }

  }

  .modal-item {
    margin-top: var(--bfs16);
    display: flex;
    flex-direction: row;

    .modal-point {
      flex-basis: 24px;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      background: var(--bfc-base-c-theme);
      border-radius: 50%;
      display: flex;

      span {
        margin: auto;
        color: var(--bfc-base-1)
      }

    }

    p {
      margin-top: 0;
      margin-left: var(--bfs8);
    }
  }


}

.error-dropdown {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -100px;
  animation-name: dropdown;
  animation-duration: 10s;
}

@keyframes dropdown {
  0% {
    top: -100px;
  }
  10%{
    top: 0;
  }
  90%{
    top: 0;
  }
  100% {
    top: -100px;
  }
}