.backoffice-editable-container {

  .edit-rot-location-table {
    tbody {
      tr:hover {
        background: var(--bfc-base-1);
      }
    }
    .edit-rot-location-row {

      > td {
        padding-bottom: var(--bfs8);
        padding-top: var(--bfs8);
      }
    }
  }


  .edit-app-filter-table {

    tbody {
      tr:hover {
        background: var(--bfc-base-1);
      }
    }
  }

  .edit-network-table {
    tbody {
      tr:hover {
        background: var(--bfc-base-1);
      }
    }
    .edit-network-row {

      > td {
        padding-bottom: var(--bfs4);
        padding-top: var(--bfs4);
      }
    }
  }

  .bf-drawer-overlay.bf-drawer-open, .bf-drawer-force-overlay.bf-drawer-open {
    z-index: 98;
  }

  .backoffice-editable-button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .backoffice-editable-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div h3 {
      margin-bottom: 30px;
      margin-top: 0;
    }

    .backoffice-button-group-left {
      display: flex;
      flex-direction: row;

      > span {

        > h3 {
          margin-bottom: 10px;
          margin-top: 0;
        }

        > label {
          margin-bottom: 20px;
          color: var(--bfc-base-c-2);
        }

      }


      > div {
        margin-left: var(--bfs16);
        > button {
        }
      }

    }

    .backoffice-button-group {

      > * {
        margin-right: var(--bfs16);
      }

      > *:last-child {
        margin-right: 0;
      }

    }

  }

  .backoffice-editable-project-teams {
    --bfl-columns: 1;
    --bfl-gap: var(--bfs20);

    > .teams-row:first-child {
      > div {
        margin-left: 0;
      }
    }

    > .teams-row:last-child {
      > div {
        margin-right: 0;
      }
    }

  }
  @media (min-width: 960px) {
    .backoffice-editable-project-teams {
      --bfl-columns: 2;
    }
  }
  @media (min-width: 1600px) {
    .backoffice-editable-project-teams {
      --bfl-columns: 4;
    }
  }

  .backoffice-editable-switchrow {
    margin-bottom: var(--bfs24);
  }

  .labeled-checkbox {

    > label {
      padding-bottom: var(--bfs12);
    }

  }

  .filter-search-row {
    display: flex;
    align-items: flex-start;

    > * {
      min-width: 100px;
      margin-right: var(--bfs8);
    }
    > *:last-of-type {
      margin-right: 0;
    }
  }

  .backoffice-editable-table {
    margin-top: var(--bfs24);

    tbody {
      tr:hover {
        background: var(--bfc-base-1);
      }
    }

    .edit-app-row {

      > td {
        padding-bottom: var(--bfs4);
        padding-top: var(--bfs4);
      }

    }

  }

  .backoffice-weird-input {
   // max-width: 100px;

    .hover-checkbox:hover {
      background: var(--bfc-base-2);
    }

    .bf-input-icon {

      > svg  {
        color: var(--bfc-success);
      }
    }
  }

  .backoffice-editable-container-drawer {
    display: flex;
    flex-direction: column;

    .checkbox-group {
      margin-top: var(--bfs24);
      --bfl-columns: 1;

      p {
        color: var(--bfc-base-c-2);
      }

    }
    @media (min-width: 600px) {
      .checkbox-group {
        --bfl-columns: 2;
      }
    }

    .input-group {
      margin-top: var(--bfs24);

      .edit-project-team-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > p {
          padding: 0 var(--bfs4);
          border-right: 1px solid var(--bfc-base-c-dimmed);
        }

        > p:last-of-type {
          border-right: 0;
        }

      }

      > p {
        margin: 0;
        color: var(--bfc-base-c-2);
      }

      > div {
        margin: var(--bfs8) 0;
      }

      .input-group-footer {
        display: flex;

      }

      .input-group-role-row {
        display: flex;
        flex-direction: row;

        > fieldset {
          flex: 1;
        }

        > button {
          border: 0;
          background: var(--bfc-base-2);
          > svg {
            color: var(--bfc-base-c-2);
          }
        }

        > button:hover {
          > svg {
            color: var(--bfc-base-c);
          }
        }

        > svg {
          cursor: pointer;
          margin: auto var(--bfs16);
        }
        > svg:hover {
          transform: scale(1.5);
        }


      }



    }

    .team-drawer-footer{
      margin-top: auto;
      display: flex;
      flex-direction: row;

      > button:last-of-type {
          margin-left: var(--bfs12);
      }

      > button:first-of-type {
        margin-right: auto;
      }

    }

  }

  .bottom-line {
    display: flex;
    justify-content: space-between;

    .table-size-dropdown {
      margin-top: var(--bfs8);
    }

    .row-box {
      display: flex;

      > div {
        width: 75px;
        margin-left: var(--bfs8);
        margin-top: var(--bfs8);
      }
    }

  }

  .edit-task-table {

    > td {
      padding-top: var(--bfs8);
      padding-bottom: var(--bfs8);
    }

  }

}

#edit-task-modal {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex: 1;
    flex-direction: row;
    //margin: var(--bfs8);

    .task-modal-global {

      > div {
        margin-bottom: var(--bfs12);
      }

    }

    .task-modal-general {

    }
  }
  > div:first-of-type {
    //margin-right: var(--bfs16);
  }
  > div:last-of-type {
    //margin-left: var(--bfs16);
  }


}
