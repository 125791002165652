#ms365-modal {
  max-width: 793px;
  max-height: 880px;

  .ms365-checkbox-row {
    --bfl-columns: 2;
  }

  .ms365-modal-select-content {
    margin-top: var(--bfs16);

    .box-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 11px;
      background: var(--bfc-base-2);

      .box-container-header {
        display: flex;
        padding: 16px 24px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-bottom: 1px solid var(--bfc-base-dimmed);

        > h5 {
          margin: 0;
          color: var(--bfc-base-c-theme);
        }

      }

      .box-container-content {
        display: flex;
        flex-direction: column;
        padding: var(--bfs24);
        align-items: flex-start;
        align-content: flex-start;
        gap: var(--bfs12);
        align-self: stretch;
        flex-wrap: wrap;

        .template-preview {
          display: flex;
          padding: var(--bfs24);
          flex-direction: column;
          align-items: flex-start;
          gap: var(--bfs12);
          align-self: stretch;
          border-radius: 12px;
          border: 1px solid var(--bfc-base-dimmed);
          background: var(--bfc-base-3);
          width: -webkit-fill-available;

          > p {
            margin: 0;
          }

          > ol {
            width: -webkit-fill-available;

            > a {
              line-break: anywhere;
            }
          }

        }

        .box-container-content-button {
          min-width: 220px;
          display: flex;
          flex-direction: column;
          gap: var(--bfs8);

          > * {
            text-align: center;
          }

        }

      }

      .box-container-content.yes-content {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

    }

  }

}