#iams-registration {

  .progressbar-container {
    margin: var(--bfs16) 0;
    display: flex;
    flex-direction: row;

    > div {
      width: 100%;
    }
  }

  .iams-content {

    .bf-tabs-container {

      .bf-tabs {
        width: 100%;

        .tabs-button {
          display: flex;
          align-self: center;
          margin-left: auto;

          > button {
            margin-right: var(--bfs8);
          }
          > button:last-child {
            margin-right: 0;
          }

        }
      }
    }

  }



  .mail-box {
    display: flex;
    flex-direction: row;

    > div {
      background: var(--bfc-base-3);
    }

    .mail-box-info {
      display: flex;
      flex-direction: row;
      border-right: 1px solid var(--bfc-base-1);

      .icon-container {
        border-radius: 100%;
        display: flex;
        height: 42px;
        width: 42px;
        background: var(--bfc-base-1);
        margin: var(--bfs16);


        > svg {
          margin: auto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          color: var(--bfc-base-c-theme);
        }

      }

      .text-container {
        display: flex;

        > p {
          margin: auto var(--bfs16) auto 0;
          // margin-right: var(--bfs16);
        }

      }

    }

    .mail-box-button {
      display: flex;
      padding: 0 var(--bfs32);

      > a {
        margin: auto;
      }

    }




  }

  .simple-top-bar {
    padding: var(--bfs16) var(--bfs24) var(--bfs16) var(--bfs16);
    background: var(--bfc-base-2);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div {
      min-width: 210px;
    }

    > fieldset {

      > div {

        > div {
          min-width: 200px;

        }

      }

    }

  }

  .content-top-bar {
    --bfl-columns: 1;
    padding: var(--bfs16) var(--bfs24);
    background: var(--bfc-base-2);

    > fieldset {

      > div {

        > div:first-of-type {
          max-width: 150px;
        }
        > div:last-of-type {
          max-width: 350px;
        }

      }

    }

    .top-bar-button-group {
      flex-direction: row;
      display: flex;
      justify-content: flex-end;

      > div {
        margin-right: var(--bfs24);
      }
      > div:last-child {
        margin-right: 0;
      }

    }


  }
  @media (min-width: 1200px) {
    .content-top-bar {
      --bfl-columns: 2;
    }
  }


  .waiting-dropdown {
    padding: 0;
  }

}

