.csv-uploader-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end; // Aligns the button-container to the right
  margin-top: var(--bfs24);
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  & > button:first-child {
    margin-right: 12px; // Adds space between the buttons
  }
}