#global-settings {

  .global-header {
    padding: var(--bfl-page-padding);
    background-color: var(--bfc-base-2);
  }

  .global-settings-content {


  }


}