#project-management-office {

  .pmo-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--bfl-page-padding);
    background-color: var(--bfc-base-2);

    > div {

      > h1 {
        margin-top: 0;
      }

    }

    .pmo-header-buttons {
      justify-self: flex-end;
      align-self: flex-end;
    }

  }

  .pmo-main-content {

    .pmo-tab-content {
      //padding: var(--bfl-box-padding);

      .pmo-resource-view {

        .pmo-resource-header {
          background-color: var(--bfc-base-3);
          padding: var(--bfs16);
          border-radius: var(--bfs16) var(--bfs16) 0 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .pmo-accordion-right-header {
            display: flex;
            justify-content: space-between;

            > span {
              display: flex;
              justify-content: space-between;
              padding-right: var(--bfs16);
            }

            .accordion-right-header-date {
              min-width: 110px;
              border-left: 1px solid var(--bfc-base-dimmed);
              padding-left: var(--bfs16);
            }

          }

          > span {
            display: flex;
            align-items: center;

            > p {
              margin: 0;
              margin-right: var(--bfs8);
              margin-left: var(--bfs16);
            }

            .pmo-strong-date {
              margin-right: var(--bfs4);
              > span {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
              }
            }

            .pmo-team-user {
              display: flex;
              align-items: center;

              > img {
                height: var(--bfs24);
                width: var(--bfs24);
                border-radius: 50%;
                margin-right: var(--bfs4);
                //margin-bottom: var(--bfs4);
              }

              .complexity-checkbox-container {
                margin-top: var(--bfs8);
                display: flex;
                flex-direction: row;
              }
            }
          }
        }

        .pmo-accordion-item-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .pmo-accordion-right-header {
            display: flex;
            flex-direction: row;

            > span {
              display: flex;
              justify-content: space-between;
              padding-right: var(--bfs16);
            }

            .pmo-accordion-right-resource {
              display: flex;
              flex-direction: row;
              border-radius: var(--bfs24);
              border: 1px solid var(--bfc-chill-c);
              padding: 0 var(--bfs12);
              align-items: center;
              margin-right: var(--bfs16);

              > div {
                width: 7px;
                height: 7px;
                border-radius: 50%;
              }

              > p {
                margin: 0;
                margin-right: var(--bfs8);
              }

            }

            .accordion-right-header-date {
              min-width: 110px;
              border-left: 1px solid var(--bfc-base-dimmed);
              padding-left: var(--bfs16);
            }

          }

        }



      }




      .pmo-accordion-header {
        display: flex;
        justify-content: space-between;

        .pmo-team-user {
          > img {
            height: var(--bfs24);
            width: var(--bfs24);
            border-radius: 50%;
            margin-right: var(--bfs4);
            //margin-bottom: var(--bfs4);
          }
        }

        .pmo-accordion-right-header {
          display: flex;
          justify-content: space-between;

          > span {
            display: flex;
            justify-content: space-between;
            padding-right: var(--bfs16);

            .pmo-team-user {

              > img {
                height: var(--bfs24);
                width: var(--bfs24);
                border-radius: 50%;
                margin-right: var(--bfs4);
                //margin-bottom: var(--bfs4);
              }

            }

            > div {
              height: var(--bfs24);
              width: var(--bfs24);
              border-radius: 50%;
              margin-right: var(--bfs4);
              margin-bottom: var(--bfs4);
              display: flex;

              > svg {
                margin: auto;
              }

            }


          }

          .accordion-right-header-date {
            min-width: 110px;
            border-left: 1px solid var(--bfc-base-dimmed);
            padding-left: var(--bfs16);
          }

        }

      }

      .pmo-tab-content-top-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: var(--bfs24);

        > * {
          margin-right: var(--bfs12);
        }

        .bf-select-container {
          //width: 200px;
        }



      }
      > div {

        .pmo-team-user-header {
          display: flex;
          width: var(--bfs24);
          height: var(--bfs24);
          justify-content: center;
          align-items: center;
          gap: var(--bfs8);
          border-radius: 3px;
        }

      }

      .pmo-user-accordion-row {
        width: 100%;
        padding: var(--bfs24) var(--bfs12);
        --bfl-columns: 10;
        --bfl-gap: var(--bfs24);


        .pmo-team-user {

          > img {
            height: var(--bfs24);
            width: var(--bfs24);
            border-radius: 50%;
            margin-right: var(--bfs4);
            //margin-bottom: var(--bfs4);
          }

          .pmo-icon-container {
            height: var(--bfs24);
            width: var(--bfs24);
            border-radius: 50%;
            margin-right: var(--bfs4);
            margin-bottom: var(--bfs4);
            display: flex;

            > svg {
              margin: auto;
            }

          }

          > span {
            display: flex;
            flex-direction: row;

            > div {
              width: 7px;
              height: 7px;
              border-radius: 50%;
            }

          }

          > p {
            margin: 0;
          }

        }
      }


    }


  }


}