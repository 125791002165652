.communication-content {

  .communication-content-main {

  }

  .communication-box-header {
    background: var(--bfc-theme-c-2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--bfc-theme-1);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .communication-box-header-text {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    svg {
      margin: 12px 16px;
    }
  }

  .communication-box-header-closed {
    border-radius: 3px;
  }

  .communication-title {
    padding: var(--bfs24);
  }

  .communication-row {
    background: var(--bfc-base-3);
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: var(--bfs12) var(--bfs24);
    margin-top: var(--bfs4);
    margin-bottom: var(--bfs4);
    border-radius: 3px;
    border: 1px solid var(--bfc-base-c-dimmed);

    .communication-row-info {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 100%;
        margin-right: var(--bfs12);
        text-align: center;
      }
    }
  }
}
