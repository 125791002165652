
*, :after, :before {
  box-sizing: border-box;
}

html {
  scrollbar-color: var(--bfc-base-c-inverted-hsl) transparent;
  scrollbar-width: thin;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: scroll !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: var(--bfc-base-c-wcag) transparent;
  scrollbar-width: thin;
}

nav {
  scrollbar-color: var(--bfc-base-c-inverted-hsl) transparent;
  scrollbar-width: thin;
}

main {
  height: 100%;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

#drawer-publish-article {
  .publish-article {
    .article-background-svg-light {
      display: none;
    }
    .article-background-svg-dark {
      display: none;
    }
  }

}
