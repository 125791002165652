#domains {

  .content {
    display: flex;

    .content-main {
      width: 100%;

      .domain-box {
        display: flex;
        flex-direction: column;

        .domain-row-dropbox {
          padding: 0 var(--bfs28);
          padding-bottom: var(--bfs16);
          --bfl-columns: 1;

          > div {
              display: flex;

            > div {
              margin-right: var(--bfs24);
            }

          }

        }

        > h5 {
          margin-top: var(--bfs24);
          margin-bottom: var(--bfs8);
        }

        .droppable {
          --bfl-columns: 1;
        }
        @media (min-width: 960px) {
          .droppable {
            --bfl-columns: 2;
          }
        }

        .box-list {
          --bfl-columns: 1;
          --bfl-gap: var(--bfs8);
        }
        @media (min-width: 960px) {
          .box-list {
            --bfl-columns: 2;
          }
        }

        .content-main-search {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

    }

  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        width: 80%;
      }
    }
  }
}