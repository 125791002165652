#printers {

  .content {
    display: flex;

    .content-main {

      .box-container {
        width: 100%;

        .printers-box {
          display: flex;
          flex-direction: column;

          .printer-card-box {
            --bfl-columns: 3;
            margin-bottom: var(--bfs8);
          }
          @media (max-width: 1280px) {
            .printer-card-box {
              --bfl-columns: 2;
            }
          }
          @media (max-width: 600px) {
            .printer-card-box {
              --bfl-columns: 1;
            }
          }

          .inner-values {
            display: flex;
            flex-direction: row;
            margin-bottom: var(--bfs16);

            > div {
              flex: 1;

              > p {
                margin: 0;
              }

            }

          }

          .printer-button-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: var(--bfs8) 0;

            > div {

              > * {
                margin-right: var(--bfs12);
              }
              > *:last-of-type {
                margin-right: 0;
              }

            }

          }

          .table-button {
            cursor: pointer;
            margin: var(--bfs2);
            margin-left: var(--bfs8);
            font-size: var(--bf-font-size-m);
            white-space: nowrap;
            background: transparent;
            border: 0;
            align-items: center;
            padding: 5px;
            display: flex;

            > svg {
              height: 16px;
              width: 16px;
            }
          }

          .table-button:hover {
            background-color: var(--bfc-base-dimmed);
            border-radius: 50%;
          }

          .table-button:focus {
            outline: none;
          }

          .table-button:focus-visible {
            background-color: var(--bfc-base-dimmed);
            border-radius: 50%;
            position: relative;
          }


        }


      }

    }

  }


}