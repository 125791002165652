#location-info {

  .content-main {

    .location-details {
      display: flex;
      flex-direction: row;


      .location-info-sidebar {
        max-width: 300px;
        display: flex;
        flex-direction: column;

        .separator {
          height: 1px;
          width: 100%;
          background: var(--bfc-base-c-dimmed);
          margin-top: var(--bfs8);
          margin-bottom: var(--bfs8);
        }

      }
      @media (max-width: 1280px) {
        .location-info-sidebar{
          max-width: 200px;
        }
      }

      .location-info-content {
        width: 100%;
        margin-left: var(--bfs16);
        display: flex;
        flex-direction: column;

        .location-info-header {
          display: flex;

          > h2 {
            margin-top: 0;
          }

          > div {
            margin: auto 0;

            > * {
              margin-left: var(--bfs8);
            }

          }


        }

        .info-progress-text {
          display: flex;
          flex-direction: row;

          > p:first-of-type {
            margin-right: var(--bfs4);
          }

          > p {
            margin-top: 0;
          }


        }

        .icon-right-accordion {

          .bf-accordion-item-title {
            display: flex;
            padding: 4px var(--bfs12);

            .bf-neutral-link-text {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

          }


        }



        .delivery-accordion {
          --bfl-columns: 5;

          > div {
            display: flex;
            flex-direction: column;

            > p {
              margin: 0;
            }

            > div:first-of-type {
              margin-top: 0;
            }

            > div {
              margin-top: var(--bfs4);
            }

          }

        }
        @media (max-width: 1160px) {
          .delivery-accordion {
            --bfl-columns: 3;
          }
        }
        @media (max-width: 960px) {
          .delivery-accordion {
            --bfl-columns: 2;
          }
        }
        @media (max-width: 800px) {
          .delivery-accordion {
            --bfl-columns: 1;
          }
        }


        .comlines-accordion {
          --bfl-columns: 5;

          > div {
            display: flex;
            flex-direction: column;

            > p {
              margin: 0;
            }

            > div:first-of-type {
              margin-top: 0;
            }

            > div {
              margin-top: var(--bfs4);
            }

          }

        }
        @media (max-width: 1160px) {
          .comlines-accordion {
            --bfl-columns: 3;
          }
        }
        @media (max-width: 960px) {
          .comlines-accordion {
            --bfl-columns: 2;
          }
        }
        @media (max-width: 800px) {
          .comlines-accordion {
            --bfl-columns: 1;
          }
        }

        .network-accordion {

          > div {
            display: flex;
            flex-direction: column;

            > p {
              margin: 0;
            }

            > div:first-of-type {
              margin-top: 0;
            }

            > div {
              margin-top: var(--bfs4);
            }

          }

        }


        .system-accordion {

          > div {


            .bf-accordion-item-title {
              > button {
                //padding-top: var(--bfs6);
                //padding-bottom: var(--bfs6);
              }

              .bf-accordion-item-actions {
                > button {
                  //padding-top: var(--bfs4);
                  //padding-bottom: var(--bfs4);
                }
              }

            }

            > div:first-of-type {

              > button {
                //padding: var(--bfs6) 0;

                > * {
                  //font-weight: 600;
                  //font-size: 16px;
                  //line-height: 150%;

                  > span {

                    > span {
                      > div {
                        margin-left: var(--bfs12);
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;

                        > svg {
                          margin-right: var(--bfs4);
                          margin-left: var(--bfs4);
                        }

                      }

                    }


                  }

                }

              }

            }

          }

        }

        .location-systems-button-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: var(--bfs16);
          margin-bottom: var(--bfs12);

          > h5 {
            margin: 0;
          }

        }

        .location-systems-container {

          .container-row {
            --bfl-columns: 5;

          }

        }




      }


    }
    @media (max-width: 600px) {
      .location-details {
        flex-direction: column;

        .location-info-sidebar{
          max-width: 100%;
        }

        .location-info-content {
          margin-left: 0;
        }

      }
    }

  }
}