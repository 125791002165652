.edit-contact {
  --bfl-columns: 2;
  margin: var(--bfs16) 0;
}

.contact-drawer-bottom-button-row {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
  justify-content: space-between;

  .button-row-collection {

    > :first-child {
      margin-right: var(--bfs16);
    }

  }
}