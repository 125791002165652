#computer-registration {

  .content {
    display: flex;

    .content-main {

      .box-container {
        width: 100%;

        .computer-registration-system-box {
          display: flex;
          flex-direction: column;

          .computer-registration-box-container {
            --bfl-columns: 1;

            .body-header {
              padding: 0 0 var(--bfs8) 0;
            }

          }

          @media (min-width: 800px) {
            .computer-registration-box-container {
              --bfl-columns: 3;
            }
          }


        }

      }
    }
  }
  @media (min-width: 1921px) {
    .content {
      justify-content: space-around;
      .content-main {
        padding: var(--bfs40) 0;
        width: 80%;
      }
    }
  }
}