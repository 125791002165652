#backoffice-information {

  .content-section {

    > h3 {
      color: var(--bfc-base-c-2);
    }

    .container-box {
      --bfl-columns: 1;
      --bfl-gap: var(--bfs8);
    }
    @media (min-width: 600px) {
      .container-box {
        --bfl-columns: 2;
      }
    }
    @media (min-width: 1280px) {
      .container-box {
        --bfl-columns: 3;
      }
    }
    @media (min-width: 1600px) {
      .container-box {
        --bfl-columns: 4;
      }
    }

    .information-button-row {
      margin-top: var(--bfs40);
      margin-bottom: var(--bfs16);
      --bfl-columns: 1;

      > div {
        margin-top: auto;
        max-width: none;
        min-width: 250px;
      }

      > div:last-of-type {
        display: flex;
        > * {
          margin-left: auto;
        }

      }
    }
    @media (min-width: 600px) {
      .information-button-row {
        --bfl-columns: 2;

        > div:first-of-type {
          max-width: 250px;
        }

      }
    }

  }


}