
.location-drawer {

  .bf-drawer-header {
    margin-bottom: var(--bfs16);
  }

  .location-drawer-content {
    display: flex;
    flex-direction: column;

    .info-content {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: var(--bfs12);

      .double-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: var(--bfs8);
      }

      .checkbox-row {

        > p {
          margin: 0;
        }

        > div:first-of-type {
          margin-right: var(--bfs8);
        }

      }

      > p {
        margin: 0;
      }

      .info-contact-box {
        background-color: var(--bfc-base-1);
        padding: var(--bfs4);
        border-radius: 3px;

        > p {
          margin: 0;
        }

      }

    }

    .separator {
      height: 1px;
      width: 100%;
      background: var(--bfc-base-c-dimmed);
      margin-top: var(--bfs8);
      margin-bottom: var(--bfs8);
    }

    .floor-plans-content {

      .floor-plan-row {
        display: flex;

        .floor-plans-badge {
          display: flex;
          align-items: center;

          > p {
            margin: 0;
          }

          > svg:first-of-type {
            margin-right: var(--bfs6);
            height: var(--bfs16);
          }
        }

        > svg {
          margin: auto 0;
          color: var(--bfc-alert);
        }

      }


    }

    .delivery-content {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: var(--bfs12);

      .information-container {
        padding: 12px;
        background: var(--bfc-base-1);
        border-radius: 3px;

        > p {
          margin: 0;
        }

      }

      .new-information-container {
        padding: 12px;
        background: var(--bfc-base-1);
        border-radius: 3px;
        --bfl-gap: var(--bfs12);

        .double-row {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: var(--bfs12);
        }

      }


    }

    .users-content {

      .header-content {

        > div {
          margin: var(--bfs12) 0;
        }


      }

    }

    .header-content {

      > div {
        margin: var(--bfs12) 0;
      }

      .header-image {
        position: relative;
        display: flex;
        flex: 1;

        > img {
          width: 100%;
          flex: 1;
        }

        .simple-uploader {
          z-index: 999999;
          position: absolute;
          top: 0;
          right: 0;
          margin: var(--bfs4);
        }

      }

    }


    .location-drawer-buttons {
      margin-top: auto;
      padding-top: var(--bfs12);
      display: flex;
      flex-direction: row;

      > button {
        margin-bottom: var(--bfs12);
      }

      > button:first-of-type {
        margin-right: auto;
      }

      > button:last-of-type {
        margin-left: var(--bfs8);
      }

    }

    .location-systems-drawer {

      > div {
        margin-bottom: var(--bfs12);
      }

      .drawer-file-documentation {

        > div:first-of-type {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > p {
            margin: auto 0 var(--bfs8);
          }
        }

        .drawer-file-container {
          padding: var(--bfs12);
          border: 1px dashed var(--bfc-base-dimmed);
          border-radius: 3px;
          flex: 1;

          > p {
            margin-bottom: 0;
            margin-top: var(--bfs8);
          }

          > span {
            margin-right: var(--bfs16);

            > svg {
              color: var(--bfc-alert);
              cursor: pointer;
            }

            .documentation {
              font-size: var(--bf-font-size-m);
              margin-right: var(--bfs8);
              color: var(--bfc-base-c-1);
              display: inline;
            }
          }
        }

      }

      .drawer-contact-adder {
        background-color: var(--bfc-base-1);
        border: 1px solid var(--bfc-base-dimmed);
        border-radius: 3px;
        padding: var(--bfs8);
        display: flex;
        flex-direction: column;

        > div {
          --bfl-columns: 2;
        }

        > button {
          margin-top: var(--bfs12);
          flex: 1;
        }

      }

      .drawer-contact-list {
        max-height: 174px;
        padding: var(--bfs4);
        border: 1px dashed var(--bfc-base-dimmed);
        overflow-x: auto;

        .drawer-contact-list-contact {
          display: flex;
          flex-direction: column;
          margin-bottom: var(--bfs8);

          > span:first-of-type {
            > svg {
              margin-left: var(--bfs12);
              color: var(--bfc-alert);
              cursor: pointer;
            }

            > p {
              margin: 0 var(--bfs4) 0 var(--bfs4);
            }
          }

          > span {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--bfc-base-c);

            > svg {
              margin: var(--bfs4);
              color: var(--bfc-base-c-2);
            }

          }

        }

      }

      .drawer-system-items {
        --bfl-gap: var(--bfs4);
        --bfl-columns: 1;


        .location-system-accordion-item {
          display: flex;
          flex-direction: row;
          background-color: var(--bfc-base-3);
          padding: var(--bfs4) var(--bfs4) var(--bfs4) var(--bfs12);

          > div {
            flex: 3;
            display: flex;
            flex-direction: row;
            align-items: center;

            > div {
              width: 100%;
              margin-right: var(--bfs8);

              > div {
                background-color: var(--bfc-base-2);
                > input {
                  background-color: var(--bfc-base-2);
                }
              }
            }

            > span {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: auto;

              > svg {
                margin-right: var(--bfs4);
              }

              > * {
                margin: 0 auto;
              }

            }

            > svg {
              margin-right: var(--bfs4);
              color: var(--bfc-base-c-2);
            }

            > p {
              margin: 0;
            }

          }

          > fieldset {
            flex: 1;

            > div {
              > div {
                > div {
                  background-color: var(--bfc-base-2);
                  > input {
                    background-color: var(--bfc-base-2);
                  }
                }
              }
            }

          }

          > button {
            cursor: pointer;
            margin: var(--bfs2);
            margin-left: var(--bfs8);
            font-size: var(--bf-font-size-m);
            white-space: nowrap;
            background: transparent;
            border: 0;
            color: var(--bfc-alert);
            align-items: center;
            padding: 5px;
            display: flex;

            > svg {
              height: 16px;
              width: 16px;
            }
          }


        }
        @media (max-width: 1280px) {
          .location-system-accordion-item {
            > div {
              flex: 2;
            }
          }
        }

      }



    }


    .meeting-room-drawer {

      .new-meeting-room {
        background-color: var(--bfc-base-1);
        padding: var(--bfs8);
        border: 1px solid var(--bfc-base-dimmed);
        border-radius: 3px;

      }

      .meeting-room-list {

        > div {
          border: 1px solid var(--bfc-base-dimmed);
          border-radius: 3px;
        }

      }

    }


  }


}

.delete-location-modal {
  max-width: 500px;

  > div {

    > div {
      margin-top: var(--bfs24);
      display: flex;
      justify-content: space-between;
    }

  }

}

