#mobile-content {

  .box-container {
    width: 100%;

    .box {
      flex-direction: column;
    }

    .mobile-apps-box {

      .mobile-apps-accordions {
        margin-top: var(--bfs24);
        display: flex;
        flex-direction: column;
        gap: var(--bfs16);
      }


    }


  }

}


#apps-accordion-custom {
  display: flex;
  padding: 4px;
  justify-content: space-between;

  .action-button {
    display: flex;
    justify-content: flex-end;
    gap: var(--bfs16);
    margin-bottom: auto;
  }

}

