main {
  height: auto;
}

#wizard {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wizard-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 20px;
  height: 100%;
}
