.elastic-logs-page {

  .content-top-bar {
    --bfl-columns: 1;
    padding: var(--bfs16) 0;
    // background: var(--bfc-base-2);

    > fieldset {

      > div {

        > div:first-of-type {
          max-width: 150px;
        }
        > div:last-of-type {
          max-width: 350px;
        }

      }

    }

    .top-bar-button-group {
      flex-direction: row;
      display: flex;
      justify-content: flex-end;

      > div {
        margin-right: var(--bfs24);
      }
      > div:last-child {
        margin-right: 0;
      }

    }


  }
  @media (min-width: 1200px) {
    .content-top-bar {
      --bfl-columns: 2;
    }
  }

  .bottom-line {
    display: flex;
    justify-content: space-between;

    .row-box {
      display: flex;

      > div {
        width: 75px;
        margin-left: var(--bfs8);
        margin-top: var(--bfs8);
      }
    }

  }

}