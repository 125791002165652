
.license-modal {
  max-height: 500px;
  min-width: 30vw;
  overflow: auto;

  > table {
    position: relative;
    
    > thead {
      top: 0;
      background-color: var(--bfc-base-3);
      position: sticky;
      position: -webkit-sticky;

      tr {
        -webkit-box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.1);
        box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.1);

      }

    }
    
  }

}