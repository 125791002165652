.location-info-sidebar-edit-icon {
  cursor: pointer;
  font-size: var(--bf-font-size-m);
  white-space: nowrap;
  background: transparent;
  border: 0;
  color: inherit;
  align-items: center;
  margin: var(--bfs2);
  padding: 4px 6px;
  border-radius: 50%;

  > svg {
    width: 17px;
  }

}

.location-info-sidebar-edit-icon:hover {
  color: var(--bfc-base-c-theme);
  padding: 3px 5px;
  border: 1px solid var(--bfc-base-dimmed);
}

.location-info-sidebar-edit-icon:focus {
  outline: none;
}

.location-info-sidebar-edit-icon:focus-visible {
  outline: 1px dashed var(--bff-theme);
  -moz-outline-radius: var(--bfl-border-radius);
  /* make sure the outline is visible on top of expanded accordion item content */
  position: relative;
  z-index: 1;
}

.general-info-card {
  background: var(--bfc-base-3);
  padding-bottom: var(--bfs8);
  border-bottom: 1px solid var(--bfc-base-c-dimmed);

  .image-container {
    max-width: 100%;
    position: relative;
    display: flex;
    flex: 1;
    min-height: 150px;
    background-size: cover;
    background-position: 50% 50%;

  }

  .info-content {
    padding: var(--bfs12);

    .info-header {
      margin-top: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > p {
        margin-top: 6px;
      }

    }

    > p {
      margin: 0;
    }

    > div {
      margin-top: var(--bfs4);

      > p {
        margin: 0;
      }

      > div {
        margin-top: var(--bfs4);
      }
    }



    .separator {
      height: 1px;
      width: 100%;
      background: var(--bfc-base-c-dimmed);
      margin-top: var(--bfs8);
      margin-bottom: var(--bfs8);
    }

    > div:last-of-type {
      margin-top: var(--bfs12);
      width: 100%;
      display: flex;

      > button {
        flex: 1;
      }

    }

  }

}

.wifi-card {
  padding: var(--bfs12);
  background: var(--bfc-base-3);
  padding-bottom: var(--bfs24);
  border-bottom: 1px solid var(--bfc-base-c-dimmed);

  .wifi-header {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > p {
      margin-top: 6px;
    }
  }

  > p {
    margin: 0;
  }

  > div {
    margin-top: var(--bfs8);

    > p {
      margin: 0;
    }

    > a {
      margin: 0;
    }

  }

}

.floorplan-card {
  padding: var(--bfs12) var(--bfs8);
  background: var(--bfc-base-3);

  > div:first-of-type {
    padding: 0 var(--bfs4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > p {
      margin: 0;
    }

    > svg {
      margin: auto 0;
      color: var(--bfc-base-c-theme);
      cursor: pointer;
    }

  }

  .floorplan-image {
    max-width: 300px;

    .annotationLayer {
      height: 0;
    }

    .empty-floorplan-container {
      width: 100%;
      height: 150px;
      background: var(--bfc-base-2);
      display: flex;
      justify-content: center;

      > p {
        margin: auto;
      }

    }

  }

  .floorplan-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > p {
      margin-top: 6px;
      margin-bottom: 0;
    }

  }

  .floorplan-container {
    margin-top: var(--bfs4);

    > div {
      margin-top: var(--bfs4);
      margin-left: var(--bfs4);
    }

  }

}