#intility-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;


  .intility-bar-header {
    background: var(--bfc-base-3);
    border-bottom: 1px solid var(--bfc-base-dimmed);
    box-sizing: border-box;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    > h3 {
      margin-top: var(--bfs12);
      margin-bottom: var(--bfs12);
    }


    > p {
      margin: var(--bfs12);
      align-self: flex-start;
    }
    > img {
      margin: auto;
      height: 30px;
    }



    .progress-box {
      width: 100%;

      > div {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        background: var(--bfc-base-2);
        align-items: center;
        border-radius: 3px;

        > div {
          display: flex;
          padding: var(--bfs2) 0;
          flex: 1;
          flex-direction: row;
          margin: 0;
          align-items: center;
          justify-content: center;

          > span {
            display: flex;
            margin: 0 var(--bfs8) 0 0;

            > svg {
              margin-right: var(--bfs8);
            }
          }

        }

        > span {
          display: flex;
          margin: 0 var(--bfs8) 0 0;

          > svg {
            margin-right: var(--bfs8);
          }
        }

        p {
          margin: 0;
        }

      }

    }



  }

  .intility-bar-content {
    padding: var(--bfs8);
    background: var(--bfc-base-3);
    flex: 2;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    > div {
      border-right: 1px solid var(--bfc-base-c-dimmed);
    }

    > div:last-of-type {
      border-right: 0;
    }

    > div:first-of-type {
      padding-right: var(--bfs8);
    }

    .visualize-box {

      > p {
        margin: 0;
        color: var(--bfc-base-c-1);
      }

      > p:last-of-type {
        margin-bottom: var(--bfs4);
      }

      > h1 {
        margin-top: var(--bfs12);
        margin-bottom: 0;
      }

    }

    #team-box {

      > p:first-child {
        cursor: pointer;
        color: var(--bfc-base-c-theme);
      }

      .picture-container {

        > img {
          margin-right: var(--bfs4);
          height: 24px;
          width: 24px;
          border-radius: 50%;
        }

      }

      .team-drawer {
        scrollbar-color: var(--bfc-base-c-wcag) transparent;
        scrollbar-width: thin;

        .team-drawer-link {
          display: flex;
          align-items: center;
          color: var(--bfc-base-c-theme);
          cursor: pointer;

          > * {
            margin-right: var(--bfs4);
          }

          > a {
            margin-top: 0;
            margin-bottom: 0;
          }

        }

        .team {

          .team-row {

            > img {
              margin-right: var(--bfs4);
              height: 24px;
              width: 24px;
              border-radius: 50%;
            }

          }

        }

      }

    }


  }


  .box-container {
    margin: var(--bfs12);
    flex: 1 4;
  }

  .box {
    margin: 0;
  }
}