.domain-row {

  td.domain-row-edit {
    text-align: end;

    > svg {
      height: 24px;
      width: 24px;
      padding: 4px;
    }

    > svg:hover {
      padding: 3px;
      border-radius: 50%;
      border: 1px solid var(--bfc-base-dimmed);
    }

  }


}